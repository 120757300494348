import React from 'react'

const BrowserActionBullets = (props) => {
    return (
        <div className="action-block">
            <div className="flex">
                <div className="action-1"> </div>
                <div className="action-2"> </div>
                <div className="action-3"> </div>
            </div>
        </div>
    )
}

export default BrowserActionBullets