import React from 'react'

const HeaderMask = (props) => {
    return (
        <div className="browser-container browser-header browser-view">
            <div className="browser">
                <div> </div>
            </div>
        </div>
    )
}

export default HeaderMask