  
import React, {useEffect} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import Team from '../Team/Team'
import Page from '../NoPageFound/NoPageFound'
import Form from '../RequestReviewForm/form';
import Home from '../Home/Home.js';
import ScamAlert from '../extension-pages/scam-alert';
import Uninstall from '../extension-pages/uninstall';
import Support from '../Support/Support';
import QikfoxSection from '../Support/QikfoxSection';
import SmartStacksSection from '../Support/SmartStacksSection';
import AntivirusSection from '../Support/AntivirusSection';
import Privacy from '../PrivacyPolicy/Privacy';
import CancelNavigation from '../CancelNavigation/CancelNavigation.js'
import Antivirus from '../Antivirus/Antivirus.js'
// import SearchHandleDomain from '../BuyHandleAndDomain/SearchHandleDomain';
// import TestGoDaddyApi from '../TestGoDaddyApi';
// import SearchFormDomain from '../BuyHandleAndDomain/Domains/SearchFormDomain';
// import SearchFormHandle from '../BuyHandleAndDomain/Handles/SearchFormHandle';
// import PurchaseScreen from '../BuyHandleAndDomain/PurchaseScreen/PurchaseScreen';
// import DomainHandleCart from '../BuyHandleAndDomain/DomainHandleCart/DomainHandleCart';
// import Userform from '../BuyHandleAndDomain/UserForm/Userform';
import Antivirusdetailspage from '../AntivirusDetailsPage/Antivirusdetailspage';
import CompareFeatures from '../Home/CompareFeatures/CompareFeatures';
import CompareFeaturestwo from '../Home/CompareFeatures/CompareFeatureTwo';
import DemoCarousel from '../Home/TestCaraousel/TestCarousel';
import TrustworthyInternet from '../TrustworthyInternet/TrustworthyInternet';
import TermUse from "../TermUse/TermUse"
import Identity from '../Identity/Identity.js';

function Routes(props) {

    useEffect(() => {
        // console.log('new build update v15')
        const timer = setTimeout(() => {
          const el1 = document.querySelector('.top_header_main_container');
          if(window.location.pathname === "/about-us" || window.location.pathname === "/antivirus" || window.location.pathname === "/trustworthy-internet" || window.location.pathname === "/compare-features-browsers"){
            el1.style.position = 'fixed';
          }else{
            el1.style.position = 'fixed';
          }
        },5)
        return () => {
          clearTimeout(timer)
        }
      });


        return (
            <Switch>
            <Route exact path="/">
            <Home />
            </Route>
            <Route path="/about-us" component={Team} />
            <Route path="/request-a-review" component={Form} />
            <Route path="/scam-alert" component={ScamAlert} />
            <Route path="/uninstall" component={Uninstall} />
            <Route exact path="/support" component={Support} />
            <Route path="/support/qikfox-browser" component={QikfoxSection} />
            <Route path="/support/smart-stacks" component={SmartStacksSection} />
            <Route path="/support/antivirus" component={AntivirusSection} />
            <Route path="/terms_of_use" component={TermUse} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/cancel-navigation" component={CancelNavigation} />
            {/* <Route path="/antivirus" component={Antivirus} /> */}
            <Route path="/identity" component={Identity} />
            <Route path="/digital-identity" component={Identity} />
            <Route path="/total-protection" component={Antivirusdetailspage} />
            <Route path="/compare-features" component={CompareFeatures} />
            <Route path="/compare-features-browsers" component={CompareFeaturestwo} />
            <Route path="/trustworthy-internet" component={TrustworthyInternet} />
            <Route path="/test" component={DemoCarousel} />
            {/**
            <Route path="/qik-handle" component={SearchHandleDomain} />
            <Route path="/testapi" component={TestGoDaddyApi} />
            <Route path="/search-domain/:domain" component={SearchFormDomain} />
            <Route path="/search-handle/:handle" component={SearchFormHandle} />
            <Route path="/purchase-confirm" component={PurchaseScreen} />
            <Route path="/cart" component={DomainHandleCart} />
            <Route path="/user-auth" component={Userform} />
            */}
            <Route path="*">
            <Page />
            </Route>
            </Switch>
        )
}

export default withRouter(Routes)