import React from 'react'
import './CompareFeatures.css';
import SvgCross from './SvgCross';

function CompareFeatures() {
    return (
        <div className="compare_features_inner_container animate fadeIn five">
            <div>
            <div className="feature_set_browser mbw"><div aria-hidden="true">BROWSE THE WEB WITH PEACE OF MIND</div><div>Safety and Security.
            </div>
            <div style={{maxWidth: '648px', margin: '0px auto'}}>
            With<strong> 1.5 Billion+ websites</strong> published on Internet, conventional browsers expose you to malicious websites, scammers, fraudulent businesses and counterfeit products. This is where qikfox Smart Browser steps in. With qikfox, you get VIP access to the very best Internet has to offer, with a <strong>safety and security</strong> guarantee.
            </div>
            </div>
            <div className="compare_features_table_main_container">
<table className="qikfox-u-data-table compare-table">
	<thead>
		<tr>
			<th>Safety and Security</th>
			<th scope="col"><img src="images/qikfox-favicon.svg" height="51" width="51" alt="Firefox" /></th>
			<th scope="col"><img src="images/CompareFeatures/chrome-icon.png" height="51" width="51" alt="Chrome" /></th>
			<th scope="col"><img src="images/CompareFeatures/safari-icon.png" height="51" width="51" alt="Edge" /></th>
            <th scope="col"><img src="images/CompareFeatures/firefox-icon.svg" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/brave-icon.png" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/edge-icon.png" height="51" width="51" alt="Opera" /></th>
			<th scope="col"><img src="images/CompareFeatures/ie-icon.png" height="51" width="51" alt="Brave" /></th>
			<th scope="col"><img src="images/CompareFeatures/vivaldi-icon.webp" height="51" width="51" alt="Internet Explorer" /></th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th scope="row">Private Browsing mode</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">InBuilt Antivirus System</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Real-Time Website Verification</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Advanced Scam Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Third Party Cookie Blocking</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Block Malicious Scripts</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Block Intrusive Trackers</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Real-Time Safety Alerts</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
		<th className='second_feature_Table'>Privacy and Identity</th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
	    </tr>
		<tr>
		<th scope="row">Private Browsing mode</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">InBuilt Antivirus System</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Real-Time Website Verification</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Advanced Scam Protection</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Third Party Cookie Blocking</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Block Malicious Scripts</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Block Intrusive Trackers</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th scope="row">Real-Time Safety Alerts</th>
		<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
	</tr>
	<tr>
		<th className='second_feature_Table'>Productivity</th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
		<th scope="col" style={{borderLeft: '0px'}}></th>
	    </tr>
	<tr>
			<th scope="row">Tab Browsing</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Bookmarks and Sync Support</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Integrated Safe Search</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Advanced Autofill</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Profile Management</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Advanced Content Sharing</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Fast Browsing via Caching</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Secure Password Management</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
	</tbody>
</table>
</div>
            </div>
        </div>
    )
}

export default CompareFeatures
