import React, { useRef } from 'react'

const useTabController = (props) => {
    const minWidth = 50;
    const MaxWidth = parseInt(window.innerWidth/13)<150?150:parseInt(window.innerWidth/13);
    const parentRef = useRef(null);
    const requiredTabCount = useRef(1);
    const animateTime = 300; // interval timer = 300/20 = 10 => not used
    const animateConstant = 10;
    const widthDelta = 2;
    const timer = useRef({});

    const getCurrentTabsCount = (ref) => {
        let count = 0;
        for(let i=0;i<ref.children.length; i++) {
            if(ref.children[i].style.opacity==1) {
                ++count;
            }
        }
        return count;
    }

    const animateWidth = (start, end, el) => {
        el.style.width = end+'px';
    }

    const reduceWidth = (el, width) => {
        let initWidth = el.offsetWidth;
        let expectedWidth = width;
        animateWidth(initWidth, expectedWidth, el);
    }

    const increaseWidth = (el, width) => {
        let initWidth = el.offsetWidth;
        let expectedWidth = width;
        animateWidth(initWidth, expectedWidth, el);
    }

    const adjustExistingWidth = () => {
        let parentWidth = parentRef.current.offsetWidth - 15;
        let splitWidth = parseInt(parentWidth/requiredTabCount.current);
        splitWidth = (splitWidth>MaxWidth)?MaxWidth:splitWidth;
        
        for(let i=0; i<parentRef.current.children.length; i++) {
            var element = parentRef.current.children[i];
            if(element.style.opacity!=1) continue;
            if(element.offsetWidth!=splitWidth) {
                // modify width, increase or decrease
                if(splitWidth<element.offsetWidth) {
                    // reduceElWidth
                    reduceWidth(element, splitWidth)
                } else {
                    // increase width
                    increaseWidth(element, splitWidth)
                }
            }
        }
    }

    const showElement = (el) => {
        if(el.style.opacity!=1) {
            el.style.opacity = 1;
        }
    }

    const hideElement = (el) => {
        if(el.style.opacity==1) {
            el.style.width = '38px';
            setTimeout(()=> {
                el.style.opacity = 0;
            }, 200)
        }
    }

    const addTab = (count) => {
        adjustExistingWidth(count);
        for(let i=0; i<count; i++) {
            var element = parentRef.current.children[i];
            showElement(element);
            setTimeout(()=> {
                adjustExistingWidth();
            }, 100)
        }
    }

    const removeTab = (count) => {
        adjustExistingWidth(count);
        for(let i=parentRef.current.children.length; i>count; i--) {
            var element = parentRef.current.children[i-1];
            hideElement(element);
            adjustExistingWidth();
        }
    }

    const setTab = (count, ref) => {
        if(!ref) return;
        if(!parentRef.current) parentRef.current = ref;
        requiredTabCount.current = count;
        let available = getCurrentTabsCount(ref);
        if(available<count) {
            addTab(count);
        } else {
            removeTab(count);
        }
    }

    return {
        setTab: setTab
    }

}

export default useTabController