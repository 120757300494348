import React from "react"
import "./Privacy.css"
function Privacy() {
  return (
    <div className={window.location.pathname === "/privacy" ? "table_upper_main animate fadeIn five" : "table_upper_main"}>
      <div class="py-12 px-6 lg:px-0 container flex flex-wrap">
        <aside class="toc mb-4 w-full lg:w-15/50 lg:pr-5 lg:toc--sticky">
          {" "}
          <strong className="table_content_heading">Table of contents</strong>
          <nav id="TableOfContents">
            <ul>
              <li>
                <a href="#security-updates">Security & Updates</a>
              </li>
              <li>
                <a href="#safe-browsing">Safe Browsing</a>
              </li>
              <li>
                <a href="#qikfox-verified-reviews">qikfox Verified Reviews</a>
              </li>
              <li>
                <a href="#location">Location </a>
              </li>
              <li>
                <a href="#qiktalk">qiktalk</a>
              </li>
              <li>
                <a href="#qikfox-search">qikfox Search</a>
              </li>
              <li>
                <a href="#qikfox-smart-stacks">qikfox Smart Stacks</a>
              </li>
              <li>
                <a href="#qikfox-antivirus">qikfox Antivirus</a>
              </li>
              <li>
                <a href="#qikfox-ai-copilot">qikfox AI Copilot</a>
              </li>
              <li>
                <a href="#how-we-improve-qikfox">How we improve qikfox</a>
              </li>
              {/* <li><a href="#miscellaneous">Miscellaneous</a></li>
				<li><a href="#copyright-dispute-policy">Copyright Dispute Policy</a></li>
				<li><a href="#qikfox-Antivirus-Subscription">Qikfox Antivirus Subscription</a></li>
                <li><a href="#qikfox-smart-stacks">Qikfox Smart Stacks (Backup Drive) Subscription</a></li>
				<li><a href="#qikfox-toatal-protection">Qikfox Total Protection Subscription</a></li> */}
            </ul>
          </nav>
        </aside>
        <div class="w-full lg:w-35/50 lg:pl-5">
          <p>
            <strong>
              <p>
                qikfox Browser does not store any record of people’s browsing history or collect usage analytics that may infringe on consumer’s privacy. We don’t write any personal data to the
                blockchain. The browser does send an anonymized heartbeat signal to tell our servers that a user is active from a cohort (which is derived as a hash of mm-yy). This way we know how
                many users from a monthly cohort are active without knowing who it is.
              </p>
              <p>Read this document to understand how the qikfox Safe Browser uses data.</p>
              <p>In this policy “we”, “us”, etc. refer to qikfox Cybersecurity Systems, Inc., while “qikfox” refers to the browser.</p>
            </strong>
          </p>
          <h2 id="security-updates">
            Security & Updates
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#security-updates" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            qikfox has the ability to update automatically from our component servers. The update is limited to pushing updated binaries and components. This ensures that you always have access to the
            latest security fixes. Our component servers use minimal aggregated data that helps us understand anonymized platform migration details and the number of users who updated. The updates do not
            collect any user-specific information.
          </p>
          <h2 id="safe-browsing">
            Safe Browsing
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#safe-browsing" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            qikfox uses a proprietary Safe Browsing API to help protect against malicious websites, downloads, and extensions that are known to be unsafe (such as sites that are fraudulent, that host
            malware, or malicious phone numbers). On iOS, we use both a proprietary Safe Browsing API and Google Safe Browsing. Apple proxies Google Safe Browsing through their own servers. More
            details at{" "}
            <a target="_blank" href="https://www.apple.com/legal/privacy/data/en/safari/">
              https://www.apple.com/legal/privacy/data/en/safari/
            </a>
            . On Android, we use a proprietary Safe Browsing API.
          </p>
          <p>On iOS, if you prefer not to use Google Safe Browsing, open “qikfox Shields & Privacy” inside settings and disable “Block Dangerous Sites”.</p>
          <h2 id="qikfox-verified-reviews">
            qikfox Verified Reviews
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-verified-reviews" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            qikfox Verified Reviews and other posts are intended for public consumption. When you submit a verified review for a website, we protect your privacy by sharing only a “universal handle”
            (universal handles are akin to domains and do not relate to your personal identity unless you choose to do so) and review. Once the review is submitted, it is public information. We advise
            users not to share false or misleading information, or slander someone online. We reserve the right to remove the review.
          </p>
          <h2 id="location">
            Location
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#location" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            If you use qikfox to visit a website that wants to determine your location, you will be asked whether you want it to be allowed to know where you are. If you click yes to this message,
            then the website will be sent an approximation of where you are based on your IP address. Your IP address is not stored by qikfox, but it may be stored by the website you have visited.
          </p>
          <h2 id="qiktalk">
            qiktalk
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qiktalk" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p className="p2">qiktalk is a private video and/or audio conference tool. What you say or type in the service is not logged or saved. Who you talk to, when, and how, is private to you.</p>
          <p className="p2"> qiktalk uses our own implementation of webRTC (STUN/TURN/ICE) to provide unified communication services using qiktalk.</p>
          <p className="p2">
            <strong>What information does qiktalk process?</strong>
            <p>We process the minimum information necessary to provide the qiktalk service. This includes:</p>
            <p className="p2">
              <ul>
                <li>Your IP address and the URL of the meeting that is processed only to enable calls; this session information is not retained after a call ends.</li>

                <li>
                  If you use the chat function, chats will be temporarily cached (client side) for the duration of the meeting. This information may go through a qikfox relay server but is never
                  stored by us.
                </li>
              </ul>
            </p>
            <p className="p2">
              <strong>To avoid scams:</strong> For the avoidance of phishing attacks, note that we at qikfox will never contact qikfox Safe Browser users in a qiktalk call. We do not authenticate
              users. Accordingly, you should never share any confidential information with anyone on qiktalk unless you are certain that you know who you are talking to. (Of course, this is a good
              practice regardless of whether you are using qiktalk, onqik, email, or any other form of communication.)
            </p>
          </p>
          <h2 id="qikfox-search">
            qikfox Search
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-search" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            We do not drop cookies, fingerprint your searches, or allow any other website to track you when using qikfox. All your searches are proxied through our anonymized relays. Websites cannot
            relate your online activity with your intent and hence cannot target ads at you.
          </p>
          <h2 id="qikfox-smart-stacks">
            qikfox Smart Stacks
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-smart-stacks" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            Any data stored and created in Stacks will be stored on a cloud storage service, to which you and only those you specifically share items with, will have access. The data is entirely
            inaccessible to qikfox and to the cloud storage provider. qikfox reserves the right to change cloud storage providers.
          </p>
          <h2 id="qikfox-antivirus">
            qikfox Antivirus
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-antivirus" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            qikfox Antivirus downloads a comprehensive list of virus definitions to the local machine for scanning. qikfox purchases these virus definitions from reputable third parties. In the case
            of a potentially malicious file, a hash of the file may be shared with our antivirus definition partner for further classification. This information contains no identifiable information.
            The antivirus system comprises a file scanner and may contain an On-Access scanner (applicable to Windows). These two scanners collectively can scan your entire system for threats.
            Anonymized threat information may be transmitted to our antivirus partner.
          </p>
          <h2 id="qikfox-ai-copilot">
            qikfox AI Copilot
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-ai-copilot" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            The AI Chat feature provides summaries of the web page you are browsing and includes a chat interface that allows you to submit questions and receive responses about the content of that
            page. The accuracy of summaries is not guaranteed and may include inaccurate, misleading, and false information. You should not submit sensitive or private information and should exercise
            caution with any text related to health, finance, personal safety, and similar cases.
          </p>
          <p>
            qikfox’s AI Chat feature is currently powered by third-party LLM. The questions you ask are forwarded to our partner’s API anonymously hence qikfox AI Copilot ensures an additional layer
            of privacy. We reserve the right to replace the third-party implementation with our own.
          </p>
          <h2 id="how-we-improve-qikfox">
            How we improve qikfox
            <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#how-we-improve-qikfox" title="Permalink to this headline">
              <i class="fas fa-link"></i>
            </a>
          </h2>
          <p>
            <strong>Privacy-Preserving Cohort Analytics</strong> <br />
            The Browser sends us anonymized cohort analytics to inform us of active status. None of the information it reports harms your privacy. The report only describes the general use of the Browser,
            such as cohort information and session length. These pings are stripped of metadata, and aggregated with measurements reported by many other instances of qikfox. The data is not personal,
            and cannot be combined in any which way to identify you.
          </p>
          <p>
            <strong>Nightly, Dev, and Beta Browser Versions</strong> <br />
            Nightly, Dev, and Beta versions of the qikfox Safe Browser are experimental previews of new qikfox Safe Browser versions. They allow us to test new features so that we can find and fix
            errors before releasing a new version of the qikfox Safe Browser.
          </p>
          <p>These incomplete versions of qikfox represent unfinished and untested work on future versions of qikfox, and their incomplete behavior may not be adequately described by this policy.</p>
        </div>
      </div>
    </div>
  )
}

export default Privacy
