import React, {useState, useEffect} from "react"
import BrowserView from "./BrowserView.js"
import CompareFeaturesButton from "./Blocks/CompareFeaturesButton"
import ModalOverlays from "./ModalOverlay.js"
import {AutoRotatingCarousel, Slide} from "material-auto-rotating-carousel"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined"
import {makeStyles} from "@material-ui/core/styles"
import "./Home.css"
import Vimeo from "@u-wave/react-vimeo"
import {CircularProgress} from "@material-ui/core"
import FeatureSection from "./FeaturesSection/FeatureSection.js"
import "./BrowserView.css"

const closeImage = <ClearOutlinedIcon />

const AutoRotatingCarouselModal = ({BannerQIkfoxSlider, setBannerQIkfoxSlider, isMobile, images, onChange, onClose, classes}) => {
  if (images !== undefined) {
    return (
      <div>
        <AutoRotatingCarousel
          label="Get started"
          open={BannerQIkfoxSlider}
          onClose={onClose}
          onStart={() => setBannerQIkfoxSlider(false)}
          classes={classes}
          autoplay={false}
          mobile={isMobile}
          onChange={index => onChange(index)}
          style={{position: "absolute"}}
          landscape={true}>
          {images !== undefined && images.map((image, index) => <Slide data-key={index} media={<img src={image} />} />)}
        </AutoRotatingCarousel>
      </div>
    )
  } else {
    return null
  }
}

export const ModalOverlayContext = React.createContext()

const Home = props => {
  const [overlay, setoverlay] = useState({modalType: "none", status: false})

  // useEffect(() =>{
  //   console.log('new build update v7')
  //  // sessionStorage.setItem('overLayValue', false);
  // },[])

  const handleOverlayValue = (value, flag) => {
    console.log("value", value)
    setoverlay({modalType: value, status: flag})
  }

  // const [BannerQIkfoxSlider, setBannerQIkfoxSlider] = useState(false);
  // const [images, setimages] = useState();

  //     useEffect(() => {
  //         if (BannerQIkfoxSlider === true) {
  //           document.documentElement.style.overflow = 'hidden';
  //           props.sendOverLayStatus(true);
  //         } else {
  //           document.documentElement.style.overflow = '';
  //           props.sendOverLayStatus(false);
  //         }
  //       }, [BannerQIkfoxSlider]);

  //       useEffect(() => {
  //         const timer = setTimeout(() => {
  //           const el1 = document.querySelector('.header-section-outer');
  //           if(BannerQIkfoxSlider ===false){
  //             el1.style.zIndex = 13;
  //           }else if(BannerQIkfoxSlider === true){
  //             el1.style.zIndex = 0;

  //           }
  //         },5)
  //         return () => {
  //           clearTimeout(timer)
  //         }
  //       }, [BannerQIkfoxSlider]);

  //       const handleImageSliderOverlay = () => {
  //         setBannerQIkfoxSlider(true);
  //         setimages(
  //           ["/images/pages/Artboard.svg", "/images/pages/Artboard Copy.svg", "/images/pages/Artboard Copy 2.svg","/images/pages/Artboard Copy 3.svg", "/images/pages/Artboard Copy 4.svg", "/images/pages/Artboard Copy 5.svg"]
  //         );
  //       }

  // const CloseModal = () => {
  //   setBannerQIkfoxSlider(false);
  //   setimages(undefined);
  //   };
  //   const matches = useMediaQuery("(max-width:600px)");

  const onChange = index => {
    // if(index === 0){
    //   sethideLeftArrow('left_arrow_btn_hide');
    //   sethideRightArrow('');
    // }else if(index > 0 && index <5){
    //   sethideLeftArrow('');
    //   sethideRightArrow('');
    // }
    // else if(index === 5){
    //   sethideLeftArrow('');
    //   sethideRightArrow('right_arrow_btn_hide');
    // }
  }

  // const onClose = () => {
  //   setBannerQIkfoxSlider(false);
  //   setimages(undefined);
  // }

  // const statusMac = (getValue) => {
  //   props.sendMacOveLay(getValue);
  // }
  // const statusWIndows = (value) => {
  //   props.sendWindowsOverLay(value);
  // }

  return (
    <ModalOverlayContext.Provider value={{overlay: overlay, handleOverlayValue: handleOverlayValue}}>
      <ModalOverlays />
      <div className={window.location.pathname === "/" ? "main-container-content animate fadeIn five" : "main-container-content"}>
        <BrowserView />
        {/**<FeatureSection /> */}

        <CompareFeaturesButton label={"Compare Features"} linkTo="/compare-features-browsers" />
        <div className="browser-content-section-2 content-section-common upper_section_main">
          <div className="browser-sections-title browser-section-title-heading">The Browser that keeps you Safe!</div>
          <div className="browser-tile upper_content_section">
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/pages/speed.svg" alt="speed" width="75"/>
                </div>
                <div className="browser-tile-title">Blazingly Fast</div>
                <div className="browser-tile-desc">Smart Browsing uses less memory and speeds up browsing by 2x.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/pages/safe-browsing.svg" alt="speed" width="56"/>
                </div>
                <div className="browser-tile-title">Safe Browsing</div>
                <div className="browser-tile-desc">qikfox browser does not let you navigate to fishy websites.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/pages/safe-search.svg" alt="speed" width="56"/>
                </div>
                <div className="browser-tile-title">Safe Search</div>
                <div className="browser-tile-desc">A built-in Safe Search Engine safeguards you from scammers.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/pages/antivirus.svg" alt="speed" width="56"/>
                </div>
                <div className="browser-tile-title">Inbuilt Antivirus</div>
                <div className="browser-tile-desc browser-tile-desc-w verifiable-desc-w">Premium version comes with Inbuilt Antivirus System.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/pages/privacy.svg" className="browser-icon-middle" alt="speed" width="75"/>
                </div>
                <div className="browser-tile-title">Privacy Pledge</div>
                <div className="browser-tile-desc browser-tile-desc-w verifiable-desc-w">qikfox browser does not let websites track your activity.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/pages/stacks.svg" alt="speed" width="65"/>
                </div>
                <div className="browser-tile-title">Smart Stacks</div>
                <div className="browser-tile-desc browser-tile-desc-w verifiable-desc-w">A revolutionary new way of browsing Internet.</div>
              </div>
            </div>
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/pages/ad-block.svg" alt="speed" width="56"/>
                </div>
                <div className="browser-tile-title">Ad-Block</div>
                <div className="browser-tile-desc browser-tile-desc-trow">Get a smooth browsing experience by removing ads and trackers.</div>
              </div>
            </div>
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/pages/verified-apps.svg" alt="speed" width="65"/>
                </div>
                <div className="browser-tile-title">Verified Apps</div>
                <div className="browser-tile-desc browser-tile-desc-trow verifiable-desc-w">Keep you safe from malware and malicious software. </div>
              </div>
            </div>

            <div className="browser-tile-item browser-tile-item-center">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/pages/apps-extensions.svg" alt="speed" width="65"/>
                </div>
                <div className="browser-tile-title">Verified Extensions</div>
                <div className="browser-tile-desc browser-tile-desc-trow verifiable-desc-w">Prevent malicious extensions from stealing your data.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="br-section-3">
          {/**<div className="content-section-common">
<div className="browser_heading">The Promise of Trustworthy Internet! </div>
</div> */}
          <div className="qk_video_container">
            <Vimeo className="video_player" video="https://player.vimeo.com/video/563490694?badge=0&autopause=0&player_id=0&app_id=58479" autoplay={false} responsive={true} />
          </div>
          {/**<div className="qikfox_text">
qikfox Smart Browser is designed to keep you safe from online scams, fake websites, and annoying ads.
 It enhances your privacy by blocking cookies, trackers and sophisticated data mining methods.
  The only browser that comes with an inbuilt anti-malware system.
  qikfox Smart Browser truly delivers on the vision of Trustworthy Internet.
</div> */}
        </div>

        <div className=" br-content-section-2">
          <div className=" content-section-common feature_numbers_section_container">
            <div className="browser-sections-title  br-section-3-title">We are on a mission to design a better Internet</div>
            <div className="browser-tile browser-mission-tile">
              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">1</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w">Democratization of the Internet.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">Consumers should be able to share content without mediators.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">2</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title">Reliable Information from reliable sources.</div>
                    <div className="browser-mission-desc">A modern Internet should propagate reliable information from credible sources.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">3</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w">Consumer Privacy.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">Internet Software should respect consumer privacy.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">4</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w">Safety and Security.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">The platform should guarantee safety and security by default.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">5</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title">Secure Communications.</div>
                    <div className="browser-mission-desc ">All communications; email, messaging, voice should be secure by default.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">6</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w">Safe Transactions.</div>
                    <div className="browser-mission-desc">The platform should guarantee safe transactions from verifiable sources.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalOverlayContext.Provider>
  )
}

export default Home
