import React from 'react'
import './CancelNavigation.css';

function CancelNavigation() {
    return (
    <div className={window.location.pathname === "/cancel-navigation" ? "cancel_nav_main_container animate fadeIn five" : "cancel_nav_main_container"}>
	<div class="qik_browser_section">
		<div class="qik_browser_img_section"><img src="/images/icons/qik-icon-cancel-nav.svg" alt="qikfox-icon" /></div>
		<div class="qik_browser_text"><span>qikfox</span> Browser</div>
	</div>
	<div class="main_heading_text_container">
		<h1>Why was this website blocked?</h1>
		<p>By definition an unsafe page is any webpage or website whose trustworthy credentials cannot be established by qikfox Safe Browsing engine. There are many reasons why a website maybe considered unsafe: Primary amongst them are “lack of business verification”, “major privacy violations”, “phishing”, “malware scripts”, “online scams” or “sale of counterfiet products”. Occasionally a business may be wrongly flagged by the automated engine. If you are a business owner and your brand has been incorrectly flagged, we encourage you to reach out to us at site-review@qikfox.com.</p>
		<p>Our goal is to promote “Trustworthy Internet” and keep our subscribers safe from cyberthreats.</p>
	</div>
	<div class="feature_list_main_container">
		<div class="feature_list_main_heading">How do we keep you safe?</div>
		<div class="feature_list_main_table">
			<div class="feature_list_table_left">
				<div class="qikfox-client__group qikfox-client__group--0">
					<div class="qikfox-client__group-content">
						<div class="qikfox-client__group-title">Safety and Security</div>
						<div class="qikfox-client__group-body">
							<div class="qikfox-client__group-description-wrapper">
								<p class="qikfox-client__group-description">qikfox Safe Browser works non-stop to safeguard you from online threats.</p>
							</div>
							<div class="border_heading"></div>
							<ul class="qikfox-client__cards">
								<li class="qikfox-client__card qikfox-client__card--0 ">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--0"><img src="/images/cancel-navigation/mal-checks-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title card__1">
											<h6> Malware Checks</h6><span class="qikfox-client__card-title-stat">With a click of a button you can scan any webpage for malware.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/unver-websites-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title card__1">
											<h6> Unverified Websites</h6><span class="qikfox-client__card-title-stat">qikfox Safe Browser warns you in real-time if we are unable to verify authenticity of a website.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/fk-auds-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title card__1">
											<h6> Fake Ads</h6><span class="qikfox-client__card-title-stat">Search Engine Ads are a major source of online scams and frauds. qikfox Safe Browser safeguards you from fake ads.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/pop-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Popups</h6><span class="qikfox-client__card-title-stat">Popups are a major source of online scams.qikfox Safe Browser blocks all those websites that use malicious popups to scam the users.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/mal-scripts-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Malicious Scripts</h6><span class="qikfox-client__card-title-stat">qikfox Safe Browser eliminates malicious scripts and analytics from websites, blocking bad actors from stealing your information.</span></div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="feature_list_table_right">
				<div class="qikfox-client__group qikfox-client__group--0">
					<div class="qikfox-client__group-content">
						<div class="qikfox-client__group-title-1">Enhanced Privacy</div>
						<div class="qikfox-client__group-body">
							<div class="qikfox-client__group-description-wrapper">
								<p class="qikfox-client__group-description">Learn how qikfox Safe Browser brings numerous technologies together to keep others from stealing your data and tracking you.</p>
							</div>
							<div class="border_heading"></div>
							<ul class="qikfox-client__cards">
								<li class="qikfox-client__card qikfox-client__card--0 ">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--0"><img src="/images/cancel-navigation/fgr-print-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Fingerprinting</h6><span class="qikfox-client__card-title-stat">Browser fingerprinting allows companies to track you and build intrusive profile on you. qikfox Browser blocks these bad actors right in their tracks.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/cake-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Third Party Cookies</h6><span class="qikfox-client__card-title-stat">Cookies are small chunks of information that websites store. This information can be used to track you. qikfox Safe browser blocks website cookies.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/auds-loc-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Ads &amp; Trackers</h6><span class="qikfox-client__card-title-stat">Ad are an annoyance and trackers are those violate your privacy . qikfox Safe Browser helps to safeguard you from Ads and Trackers.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/dec-identity-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title">
											<h6>Decentralized Identity</h6><span class="qikfox-client__card-title-stat">Your data, your rules. Decentralized Identity helps you engage service providers privately without identity providers snooping on you.</span></div>
									</div>
								</li>
								<li class="qikfox-client__card qikfox-client__card--1">
									<div class="qikfox-client__card-header js-toggle">
										<div class="qikfox-client__card-state">
											<div class="qikfox-client__card-number " id="card-number--1"><img src="/images/cancel-navigation/secure-dns-icon.svg" alt="malware" /></div>
										</div>
										<div class="qikfox-client__card-title card__1">
											<h6>Secure DNS</h6><span class="qikfox-client__card-title-stat">Secure DNS means reliable websites. qikfox Safe Browser allows you to configure your favorite secure DNS Servers.</span></div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
    )
}

export default CancelNavigation