import React from 'react'

const NavigationArrows = (props) => {
    return (
        <div className="navigation flex"> 
            <div className='arrow-left'></div>
            <div className='arrow-right'></div>
        </div>
    )
}

export default NavigationArrows