import React from 'react'
import './Team.css'

function Team() {
    


    return (
        <div className={window.location.pathname === "/about-us" ? "team_main_container_1 animate fadeIn five" : "team_main_container_1"}>
        <div className="heading_container">
        <h1>The Team</h1>
        <h5>We are on a mission to design a Trustworthy Internet. </h5>
        </div>
        <div className="image_blob_main">
        <div className="image_blob_container">
        <div className="image_blob_inner">
        <img src="/images/team/blob01_tarun.png" alt="team_img_1"/>
        <h5>Tarun Gaur</h5>
        <h5>CEO</h5>
        <p>Steers the ship</p>
        </div>
       {/* <div className="image_blob_inner">
        <img src="/images/team/blob02_john.svg" alt="team_img_1"/>
        <h5>John Paris</h5>
        <h5>Chief Content Officer</h5>
        <p>Battens down the Hatches</p>
    </div>*/}
        <div className="image_blob_inner">
        <img src="/images/team/blob04_wyatt_old.svg" alt="team_img_1"/>
        <h5>Wyatt Lutt</h5>
        <h5>Software Architect</h5>
        <p>Loads the cannons</p>
        </div>
        <div className="image_blob_inner">
        <img src="/images/team/blob05_ian.png" alt="team_img_1"/>
        <h5>Ian Wagner</h5>
        <h5>Director, Development</h5>
        <p>Blows wind on the sails</p>
        </div>
      {/**  <div className="image_blob_inner">
        <img src="/images/team/david-new-team-photo.png" alt="team_img_1"/>
        <h5>David Akerman</h5>
        <h5>Product Marketing</h5>
        <p></p>
        </div> */}
        <div className="image_blob_inner">
        <img src="/images/team/blob03_haley.svg" alt="team_img_1"/>
        <h5>Haley Welsch</h5>
        <h5>Chief Designer</h5>
        <p>The fairy dust</p>
        </div>
        <div className="image_blob_inner">
        <img src="/images/team/blob06_curtis.svg" alt="team_img_1"/>
        <h5>Curtis K.</h5>
        <h5>Frontend Architect</h5>
        <p>The Shipwright</p>
        </div>
        <div className="image_blob_inner">
        <img src="/images/team/arun-new-team-photo.png" alt="team_img_1"/>
        <h5>Arun Rajesh</h5>
        <h5>VP Web</h5>
        <p>Hoists the mainsail</p>
        </div>
        {/**<div className="image_blob_inner">
        <img src="/images/team/blob09_sameer.svg" alt="team_img_1"/>
        <h5>Sameer A.</h5>
        <h5>Cloud Architect</h5>
        <p>A warp in the clouds</p>
        </div> */}
        <div className="image_blob_inner">
        <img src="/images/team/shashank-new-team-photo.png" alt="team_img_1"/>
        <h5>Shashank B.</h5>
        <h5>Director, Web
        </h5>
        <p>Hip Hip Array</p>
        </div>
        <div className="image_blob_inner">
        <img src="/images/team/blobl08_smahi.png" alt="team_img_1"/>
        <h5>Smahi Maini</h5>
        <h5>Search Architect</h5>
        <p>Graph - Ahead of the curve?</p>
        </div>
        <div className="image_blob_inner">
        <img src="/images/team/blob10_pawan.png" alt="team_img_1"/>
        <h5>Pawan Kumar</h5>
        <h5>Associate Architect</h5>
        <p>Booleaneer</p>
        </div>
        {/***<div className="image_blob_inner">
        <img src="/images/team/blob11_vikas.png" alt="team_img_1"/>
        <h5>Vikas</h5>
        <h5>Business Analyst</h5>
        <p>Solves Problems</p>
        </div> */}
        <div className="image_blob_inner">
        <img src="/images/team/amit_blob_001.svg" alt="team_img_1"/>
        <h5>Amit Saini</h5>
        <h5>Product Manager</h5>
        <p>Probes Solutions</p>
        </div>


        <div className="image_blob_inner">
        <img src="/images/team/luan_blob_02.svg" alt="team_img_1"/>
        <h5>Luan Freitas</h5>
        <h5>Mathematician</h5>
        <p>Reads the Stars</p>
        </div>


        <div className="image_blob_inner">
        <img src="/images/team/ketan_blob_02.svg" alt="team_img_1"/>
        <h5>Ketan</h5>
        <h5>Software Developer</h5>
        <p>Rows the Boat</p>
        </div>

        <div className="image_blob_inner">
        <img src="/images/team/roushan_blob_002.svg" alt="team_img_1"/>
        <h5>Roushan</h5>
        <h5>Software Developer</h5>
        <p>Brogrammer</p>
        </div>

        <div className="image_blob_inner">
        <img src="/images/team/rahul_blob_02.svg" alt="team_img_1"/>
        <h5>Rahul</h5>
        <h5>Software Developer</h5>
        <p>Codelling</p>
        </div>



        </div>
        </div>
             <div className="cuddle_crew_container">
        <h1>Cuddle Crew</h1>
        </div>
             <div className="image_blob_main_1">
        <div className="image_blob_container_1">
        <div className="image_blob_inner_1">
        <img src="/images/team/blob-pet01_loki.svg" alt="team_img_1"/>
        <h5>Loki</h5>
        <h5>Chief Ruckus Officer</h5>
        <p>I love strawberries</p>
        </div>
        <div className="image_blob_inner_1">
        <img src="/images/team/blob02_pet_thor.svg" alt="team_img_1"/>
        <h5>Thor</h5>
        <h5>Chief Cuddle Officer</h5>
        <p>I hired the CEO</p>
        </div>
        <div className="image_blob_inner_1">
        <img src="/images/team/blob03_pet_kirby.svg" alt="team_img_1"/>
        <h5>Kirby</h5>
        <h5>Chief Calm Officer</h5>
        <p>I herd the cats</p>
        </div>
        <div className="image_blob_inner_1">
        <img src="/images/team/freya-blob-01.svg" alt="team_img_1"/>
        <h5>Freya</h5>
        <h5>Napper Snapper</h5>
        <p>Don't mess with me</p>
        </div>
        </div>
        </div> 
        </div>
    )
}

export default Team
