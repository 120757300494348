import React, { useContext, useEffect, useRef, useState } from 'react'
import BrowserTab from './BrowserTab';
import useTabController from './tab-controller';
import AddressBar from './Browser-Elements/AddressBar';
import BrowserBody from './Browser-Elements/BrowserBody';
import HeaderMask from './Browser-Elements/HeaderMask';
import BrowserActionBullets from './Browser-Elements/BrowserActionBullets';
import FooterMask from './Browser-Elements/FooterMask';
import BrowserPages from './BrowserPages';
import './BrowserView.css';
import DemoCarousel from './TestCaraousel/TestCarousel';
import BrowserButtons from './BrowserButtons/BrowserButtons';
import { ModalOverlayContext } from './Home';

let direction = 'down';
const BrowserView = (props) => {
  // const modalOverlayValue = useContext(ModalOverlayContext);
  // console.log('modalOverlayValue',modalOverlayValue)

  const [tabs, setTabs] = useState([true, true, true, true, true, true, true, true]);
  const tabParent = useRef(null);
  const tabsRef = useRef(1)
  const positionRef = useRef({offsetTop: 0, parentHeight: 0});
  const tabController = useTabController();
  const [slideNumber, setslideNumber] = useState(1);

  let oldScrollY = 0;
  let overlayValue=sessionStorage.getItem('overLayValue');
//  console.log('overlayValue',overlayValue);

  // useEffect(() =>{
  //   overlayValue= 
  //   console.log(overlayValue);
  //  // sessionStorage.setItem('overLayValue', false);
  // },[]);

  const currentSlideValue = (value) =>{
    let oldSlideNumber = slideNumber
    let newSlideNumber = value
    //console.log('oldSlideNumber',oldSlideNumber);
    //console.log('newSlideNumber',newSlideNumber);
    setslideNumber(value);
  }

  const tabsRender = () =>{
    if(slideNumber >=1){
     return(
     <React.Fragment>
     {[...Array(slideNumber)].map(
      (value, index) => (
        <BrowserTab id={index + 1} key={index} slideNumber={slideNumber} active={index +1}
        {...props}
        />
      )
    )}
     </React.Fragment>
     )
    }
  }

  return (
        <div>
          <div className={`browser-view ${props.page?props.page:''}`}>
            <HeaderMask />
            <div className="browser-container dark top">
                <div className="browser">
                    <div className="flex col">
                        <div className="tab-container">
                            <div className="flex">
                                <BrowserActionBullets />
                                <div className="tab-block extend">
                                    <div className="flex-parent" id="browser-tab-parent" ref={tabParent}>
                                        {tabsRender()} 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AddressBar />
                    </div>
                </div>
            </div>
            <BrowserBody slideValue={currentSlideValue} 
            {...props}
            />
            {/**<FooterMask /> */}
          </div>
          

          {/**
        <div className="browser_inner_body_outer_container">
          <div className='browser_inner_body_main_heading'>A new era of Internet Browsing has begun.</div>
          <DemoCarousel propsSlideValue={getSlideNumber}/>
          <BrowserButtons />
          </div>
        */}

        </div>
  )
}

export default BrowserView