import React, {useEffect} from 'react'
import './css/style.css'
import {getCookie, delete_cookie} from './GetCookie';
import { useLocation } from 'react-router-dom';

function Uninstall() {
   const location = useLocation();

   useEffect(() => {
      checkCookieData();
   }, [location]);

   const checkCookieData = () => {
      var UninstallUrl=window.location.href.split("?")[1];
      if(UninstallUrl==undefined){
         window.location.href="/";
      }
      else{
         if (getCookie("Uninstall") != "") {
               var Uninstall = getCookie("Uninstall");	
                  var Uninstalldata = {
                     'CustomerID': Uninstall
                  };

            fetch('https://login2-dot-qikfox2.appspot.com/login/uninstall/',{
               mode: 'cors',
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
                   'Accept': 'application/json',
                   'Access-Control-Allow-Origin': '*'
               },
               body: JSON.stringify(Uninstalldata)
            }).then(res => res.json())
            .then((response) => {
               if(response.StatusCode == 1){
                  delete_cookie("Uninstall");	
						delete_cookie("CustomerID");	
               }
            }).catch((err) => {
               if(err){}
            });
         }
      }
   }

   return (
      <div>
      <div class="main-body" data-role="page">
      <div class="main-unistall">
   
         <div class="ascam-header">
      <div class="ascam-header-2">
      <div class="ascam-header-2-inner">
      <div class="scammer-img-section"><img src="/images/extension/qf-shapeless-logo.png" class="hacker-img"/></div>
      <div class="hacker-desc">
      <div class="ascam-header-desc uninstall-title">qikfox</div>
   <div class="sorry-unistall">We are sorry to see you go</div>
</div>
      </div>
      
      </div>
      
      </div>
      <div class="content-bg-scam">
      <div class="frameTC-bg">
         <div class="content-scam-alert content-uninstall">
      <div class="content-scam-1-inner">				
      <div class="scam-text-2">Qikfox Scam Protection has been uninstalled from your browser. Your computer may be at risk of a scam. Call <a href="tel:+1-888-801-1555" class="numberalertuninstall ui-link">+1-888-801-1555</a> to talk to an expert.</div>
      </div>
</div></div>
         </div>

      
      </div>
      </div>
      </div>
   )
}

export default Uninstall
