import React from "react"
import Home from "../Home/Home"
import BrowserView from "../Home/BrowserView"
import CompareFeaturesButton from "../Home/Blocks/CompareFeaturesButton"
import {identityPageContent} from "../Home/StaticPageContent"
import Vimeo from "@u-wave/react-vimeo"
import "./identity.css"
import '../App.css'
import Section5 from "./Section5"

function Identity() {
  return (
    <React.Fragment>
      <div className={window.location.pathname === "/identity" ? "main-container-content animate fadeIn five" : "main-container-content"}>
        <BrowserView staticData={identityPageContent} page="identity" />
        <CompareFeaturesButton label={"Contact Us"} linkTo="/support" />
        <div className="browser-content-section-2 content-section-common upper_section_main">
          <div className="browser-sections-title browser-section-title-heading">A New World of Digital Trust!</div>
          <div className="browser-tile upper_content_section">
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/identity/self-soverign-icon-01.svg"
                  width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Self-Sovereign</div>
                <div className="browser-tile-desc">You establish your digital persona and what you do with it. Your control.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/identity/private-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Private</div>
                <div className="browser-tile-desc">Privacy-preserving blockchain based infrastructure.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/identity/secure-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Secure</div>
                <div className="browser-tile-desc verifiable-desc-w">Tamper-proof and encrypted end to end messaging.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/identity/interoperable-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Interoperable</div>
                <div className="browser-tile-desc">Integrate easily with other W3C compliant decentralized IDPs.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/identity/zero-trust-icon-01.svg"
                   width="56"
                  className="browser-icon-middle" alt="speed" />
                </div>
                <div className="browser-tile-title">Zero-Trust</div>
                <div className="browser-tile-desc browser-tile-desc-w">Freedom from digital tracking via zero-trust imperatives.</div>
              </div>
            </div>

            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/identity/verifiable-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Verifiable</div>
                <div className="browser-tile-desc browser-tile-desc-w verifiable-desc-w">Every credential is independently verifiable.</div>
              </div>
            </div>
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner browser-tile-start">
                <div className="browser-tile-img">
                  <img src="/images/identity/biometrics-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Biometrics</div>
                <div className="browser-tile-desc browser-tile-desc-trow verifiable-desc-w">Authentication and verifications via biometrics.</div>
              </div>
            </div>
            <div className="browser-tile-item">
              <div className="browser-tile-item-inner">
                <div className="browser-tile-img">
                  <img src="/images/identity/extensible-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">Extensible</div>
                <div className="browser-tile-desc browser-tile-desc-trow">Scale out your identity system by adding more credential types.</div>
              </div>
            </div>

            <div className="browser-tile-item browser-tile-item-center">
              <div className="browser-tile-item-inner browser-tile-end">
                <div className="browser-tile-img">
                  <img src="/images/identity/W3C-standards-icon-01.svg"
                   width="56"
                  alt="speed" />
                </div>
                <div className="browser-tile-title">W3C Standards</div>
                <div className="browser-tile-desc identity-width-title-last-section-2">Standards means seamless integration and interoperability.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="br-section-3">
          <div className="content-section-common">
            <div className="identity-section-3">
              <div className="identity-section-3-inner-1">
                <div className="identity-section-3-title">Decentralized Identity is the Future. Are you Ready?</div>
                <div className="identity-section-3-desc">
                  Privacy-preserving, open standards based decentralized Identity System built on public and permissioned blockchains is the future of identity. WorldPass from qikfox is the
                  state-of-the-art BYOI (Bring your own identity) system that is DIF, W3C and EUDI compliant. With Universal Handles, you get human-readable identity that is fully portable,
                  extensible, and based on the latest next-generation technology stack. With decentralized identity stack maturing, it does not make sense to go back to enterprise or federated
                  identities. <br/><br/>Fully integrated with Centralized and Federated Identity Systems for backward compatibility, WorldPass makes the transition to the future seamless.
                </div>
              </div>
              <div className="identity-section-3-inner-2">
                <div className="identity-section-3-img">
                  <img src="/images/identity/chart-infographic-01.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" br-content-section-2 identity-video">
          <div className="qk_video_container">
            <Vimeo className="video_player" video="https://player.vimeo.com/video/884490980?h=6cc9c99022&badge=0&autopause=0&player_id=0&app_id=58479" autoplay={false} responsive={true} />
          </div>
        </div>

        <Section5 />


        <div className=" br-content-section-2">
          <div className=" content-section-common feature_numbers_section_container">
            <div className="browser-sections-title  br-section-3-title">Universal Identity means a more Trustworthy Internet.</div>
            <div className="browser-tile browser-mission-tile">
              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">1</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title identity-width-title-last-section">Bring your own Identity.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">Consumers should own their digital identity and bring it along.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">2</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title identity-width-title-last-section">Your Data, Your Control.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">Digital Identity should enable consumers to control their data.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">3</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w3">Respect for Consumer Privacy.</div>
                    <div className="browser-mission-desc">Service Providers must respect consumer privacy and build systems to ensure that.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">4</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title browser-mission-title-w">Auditable Interactions.</div>
                    <div className="browser-mission-desc browser-mission-desc-w">Regulatory bodies audit interactions based on consumer permissions.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">5</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title identity-width-title-last-section">Minimal Access.</div>
                    <div className="browser-mission-desc ">Service Providers must avoid storing consumer data and never use it out of context.</div>
                  </div>
                </div>
              </div>

              <div className="browser-mission-tile-item">
                <div className="browser-mission-tile-item-inner">
                  <div className="browser-mission-number">6</div>
                  <div className="browser-mission-text">
                    <div className="browser-mission-title identity-width-title-last-section">Open Standards.</div>
                    <div className="browser-mission-desc">Vendor lock-ins are a thing of the past. Open means transparent and trustworthy.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default Identity
