import React from 'react';
import './Antivirusdetailspage.css';

function Antivirusdetailspage() {

    const openLivechat=()=>{
        window.$zoho.salesiq.floatbutton.visible("show")
        window.$zoho.salesiq.floatwindow.visible("show")
    }

    return (
        <div className="animate fadeIn five">
        <div className="main_container">
            <div className="header_container header">
                <div className="qikfox_section">
                    <img height="50" width="50" src="images/qikfox-favicon.svg" />
                    <h6>qikfox</h6>
                </div>
                <div className="heading_section">
                    <h6>- TOTAL PROTECTION FOR YOUR DIGITAL LIFE -</h6>
                    <h1>Special Deal <span>Save 40%</span> Off Today</h1>
                    <p>Safeguard your computer from Viruses, Malware, Trojans, Spyware, Popups, Scams, Annoying Ads and more.</p>
                </div>
            </div>
        </div>
        <div className="main_content">
            <div className="header_container main_content_container">
               <div className="main_content_container_inner">
              <div className="main-content-qikfox">
              <div className="main_content_qikfox_tile">
              <div className="qikfox_tile_image">
                  <img src="images/qikfox-antivirus-main-image.jpeg" />
              </div>
              <div className="qikfox_tile_content">
                  <h5>qikfox Total Protection 2021 + IdentityForce
                  </h5>
                  <p>
                  80% of malware is downloaded while browsing Internet and yet conventional antivirus fails to safeguard you while browsing the web. qikfox is a modern end-point cybersecurity system that protects you against Viruses, Malware, Trojans, Spyware, Popups, Scams, Annoying Ads and more. Identity Force protects your identity on dark and conventional web. 
                  </p>
              </div>
              <div className="qikfox_tile_pricing">
                  <h5>
                      Special Price
                  </h5>
                  <h5>
                      $150
                  </h5>
                  <h6>
                      Regular <span>$249</span>
                  </h6>
                  <a href="https://store.qikfox.com" target="_blank" rel="noopener noreferrer">
                  <button>Buy Now</button>
                  </a>
              </div>
          </div>
          <div className="main-qik-tile-btns-container">
        <a href="tel:+1-888-801-1555">
        <button><span><img src="images/tel-icon.png" alt="tel"/></span>1 (888) 801-1555</button>
        </a>
          <button onClick={openLivechat}><span><img src="images/live-chat.png" alt="chat"/></span>Live Chat</button>
          </div>
              </div>
                <div className="main_content_feature_tile pt">
                    <div className="feature_tile_side_ribbon side_image">
                        <img src="images/included02.png" />
                    </div>
                    <div className="feature_tile_left">
                        <img width="65" src="images/noun_Antivirus_3120204.svg" />
                    </div>
                    <div className="feature_tile_right">
                        <h6>Advanced Malware Protection
                        </h6>
                        <p>
                            Powered by an advanced Antivirus and Malware Protection Engine. Antivirus definitions are updated every hour giving you an unmatched levels of protection. 
                        </p>
                    </div>
                </div>
                <div className="main_content_feature_tile">
                <div className="feature_tile_side_ribbon side_image">
                    <img src="images/included02.png" />
                </div>
                <div className="feature_tile_left identityclass">
                    <img width="65" src="images/newidentity.png" />
                </div>
                <div className="feature_tile_right">
                    <h6>Identity Protection</h6>
                    <p>
                    qikfox + IdentityForce work together to safeguard your identity on the dark and conventional web. Comprehensive protection for your digital life. 
                    </p>
                </div>
                </div>
                <div className="main_content_feature_tile">
                    <div className="feature_tile_side_ribbon side_image">
                        <img src="images/included02.png" />
                    </div>
                    <div className="feature_tile_left">
                        <img width="70" src="images/newsafe.png" />
                    </div>
                    <div className="feature_tile_right">
                        <h6>Secure Browser</h6>
                        <p>
                            qikfox antivirus is integrated with qikfox secure browser. An advanced safety, security and privacy oriented browser that safeguards you from online threats in real time.                    </p>
                    </div>
                </div>
                <div className="main_content_feature_tile">
                    <div className="feature_tile_side_ribbon side_image">
                        <img src="images/included02.png" />
                    </div>
                    <div className="feature_tile_left">
                        <img width="65" src="images/Group 3.svg" />
                    </div>
                    <div className="feature_tile_right">
                        <h6>Advanced Scam Protection</h6>
                        <p>
                            qikfox Total Protection system safeguards from you from online Scams. No other cybersecurity system provides scam protection. 
                        </p>
                    </div>
                </div>
                <div className="main_content_feature_tile">
                    <div className="feature_tile_side_ribbon side_image">
                        <img src="images/included02.png" />
                    </div>
                    <div className="feature_tile_left">
                        <img width="65" src="images/noun_hacker_3627929.svg" />
                    </div>
                    <div className="feature_tile_right">
                        <h6>Privacy Guard</h6>
                        <p>
                            qikfox Secure Browser blocks cookies, annoying ads and guards your privacy. A truly revolutionary approach to keeping your information safe while browsing.                     </p>
                    </div>
                </div>
                <div className="main_content_feature_tile">
                    <div className="feature_tile_side_ribbon side_image">
                        <img src="images/included02.png" />
                    </div>
                    <div className="feature_tile_left">
                        <img width="65" src="images/noun_Call%20Center_2004731.svg" />
                    </div>
                    <div className="feature_tile_right">
                        <h6 >Managed Services
                        </h6>
                        <p>
                            Never pay for technical support again. As a qikfox Total Protection Customer you have access to 24/7 technical support.  </p>
                    </div>
                </div>
               </div>
            </div>
        </div>
       </div>
    )
}

export default Antivirusdetailspage
