import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import { useHistory } from 'react-router';
import './innerpages.css'

function SmartStacksSection() {
    const history = useHistory();


    const handleBackButton = () => {
        history.goBack();
    }


    const data = [
        {"id": 1, "title": "Why use qikfox search instead of Google?", "content": "Unlike google search, qikfox search respects your privacy and guarantees safe search results. Google tracks your online presence via google accounts, their search engine and their trackers have been found on more than 70% of top million websites. Google tracks your online activity, qikfox does not. We guarantee your privacy."},
        {"id": 2, "title":" How do qikfox search results compare to Google’s?", "content": "qikfox search is evolving but we are pretty accurate. In comparison, Google search results are littered with ads, sponsored listings and even sub-par SEO optimized search results. Qikfox search results are safe. Qikfox search engine cannot be gamed using techniques like SEO to force sub-par results to the top. Qikfox search results deliver quality over quantity."},
        {"id": 3, "title":" How can I contribute to make search better?", "content": "qikfox search is part automated and part community driven. Sign up as a community search expert and help us rate websites and URLs. Your inputs as a consumer are invaluable, especially around local results. We plan to launch “community search master dashboard” in Q3 2021."},
        {"id": 4, "title":"  What are “verified search results”?", "content": "All search results in qikfox results pages are verified for safety and relevance. Our machine learning engine combined with proprietary algorithms verifies each URL and related TLDs for legitimacy. We check multitude of variables to tag websites as “safe” and “reliable”. Afterall we are on a mission to design a Trustworthy Internet."},
        {"id": 5, "title":"  How does qikfox search guarantee privacy?", "content": "We do not drop cookies, fingerprint your searches or allow any other website to track you in any which way. Websites cannot relate your online activity with your intent and hence cannot target ads at you. We take consumer privacy seriously."},
        {"id": 6, "title":"What is trustworthy Internet?", "content": "Trustworthy Internet is a philosophy and our mission. It is our rallying cry. We are on a mission to design a trustworthy Internet - an Internet that provides consumers access to reliable information, secure conversations, and safe transactions. All our products, solutions and services are being designed to make the Internet safe, fast, reliable and more democratic. Safety, Privacy and Choice are the core design principles."},
        {"id": 7, "title":" How to contact qikfox product team?", "content": "We are accessible via our website, twitter, facebook or reddit channels. Alternatively you can reach out to us at hello@qikfox.com or media@qikfox.com for media inquiries."},
    ]

    return (
        <div className={window.location.pathname === "/support/smart-stacks" ? "faq-section animate fadeIn five" : "faq-section"}>
        <span onClick={handleBackButton} className="back_button"><img src="/images/support/back-arrow-faq.svg" alt="back-img" /></span>
        <h1 className="faq-heading">Frequently Asked Questions</h1>
        <Accordion className="accordion-main" allowZeroExpanded={true}>
        {data.map((item, index) => (
            <AccordionItem className="accordion-item" uuid={item.id} key={index}>
                <AccordionItemHeading className="accordion-title">
                    <AccordionItemButton className="accordion-title-button">
                    <AccordionItemState>
                    {({ expanded }) => (expanded ? <div><img className="dark-image" src="/images/arrow-down-black.svg" /><div className="text">{item.title}</div></div> : <div><img className="dark-image" src="/images/arrow-up-black.svg" /><div className="text">{item.title}</div></div>)}
                     </AccordionItemState>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-content">
                  {item.content}
                </AccordionItemPanel>
            </AccordionItem>
        ))}
        </Accordion>
        </div>
    )
}

export default SmartStacksSection