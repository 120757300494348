import React, {useRef, useState, useEffect} from "react"
import SelectCountryFlagData from "./country.json"
import "./SelectCountryFlag.css"

function SelectCountryFlag(props) {
  const wrapperRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [clearSearch, setClearSearch] = useState(false)
  let flagsList = SelectCountryFlagData.filter(d => searchInput.toLowerCase() === "" || d.countryName.toLowerCase().includes(searchInput.toLowerCase()))

  useEffect(() => {
    console.log(SelectCountryFlagData)
    flagsList = SelectCountryFlagData
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false)
      setSearchInput("")
      setClearSearch(false)
    }
  }

  const showFlags = () => {
    setIsVisible(!isVisible)
    setSearchInput("")
    setClearSearch(false)
  }

  const getSelected = value => {
    //console.log(value)
    props.onSelected(value)
    setIsVisible(false)
  }

  const onKeyPress = e => {
    let clearOnKeyPress
    clearTimeout(clearOnKeyPress)
    if (clearSearch) {
      setSearchInput(e.key)
      setClearSearch(false)
    } else {
      setSearchInput(searchInput + e.key)
    }

    clearOnKeyPress = setTimeout(() => {
      setClearSearch(true)
    }, 2500)
  }

  const setDropdownTemplate = () => {
    if (flagsList.length > 0) {
      return flagsList.map((item, index) => (
        <div className="select_country_flag_option_item" key={index} onClick={() => getSelected(item)}>
          <div className={`select_flag_option-inner ${item.separator && "select-flag-seprator"}`}>
            <div className="select_country_flag_option_image">
              <img src={`/images/countryFlags/${item.countryFlag}`} alt={item.countryName} />
            </div>
            <div className="select_country_flag_option_name">
              <div className="country_name">{item.countryName}</div>
            </div>
            <div className="select_country_flag_option_code">
              <div className="country_code">(+{item.countryCode})</div>
            </div>
          </div>
        </div>
      ))
    } else {
      return <div className="select_country_flag_option_item">No Country Found</div>
    }
  }

  return (
    <div className={`select_country_flag_outer_container ${props.className && props.className}`} tabIndex="0" onKeyPress={onKeyPress} ref={wrapperRef}>
      <div className="select_country_flag_main">
        <div className="select_country_flag_item" onClick={showFlags}>
          <div className="select_country_flag_image">
            <img src={`/images/countryFlags/${props.currentSelected.countryFlag}`} alt={props.currentSelected.countryName} />
          </div>
          <div className="select_country_flag_code">
            <div className="country_code">(+{props.currentSelected.countryCode})</div>
          </div>
        </div>
        {isVisible && <div className="select_country_flag_options">{setDropdownTemplate()}</div>}
      </div>
    </div>
  )
}

export default SelectCountryFlag
