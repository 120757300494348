import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group';

const BrowserTab = (props) => {
    let {id, active,slideNumber, page} = props
   // console.log(slideNumber)
    const renderBasedOnSlide = () =>{
        // if(id< 1 || id == 0){
        //     return(
        //         <div id={`tab-index-${1}`} style={{width: '140px', opacity: 1}} className='tab-element'>
        //         <div>
        //             <div className="tab flex">
        //                 <div className="circle bg-bar"></div>
        //                 <div className="bar extend bg-bar extend"></div>
        //             </div>
        //         </div>
        //     </div>
        //     )
        // }
        let conditionals
        if(page && page === 'identity'){
            conditionals = id >=1 && id <=3
        }else{
            conditionals = id >=1 && id <=7
        }
        if(conditionals){
            return(
                <div id={`tab-index-${id}`} style={{width: '140px', opacity: 1}} className='tab-element'>
                <div>
                    <div className={`tab flex ${slideNumber==id && 'bgColor'}`}>
                        <div className={`circle bg-bar ${slideNumber==id && 'activebgColor'}`}></div>
                        <div className={`bar extend bg-bar extend ${slideNumber==id && 'activebgColor'}`}></div>
                    </div>
                </div>
            </div>
            )
        }
        if(conditionals){
            return(
                <div id={`tab-index-${id}`} style={{width: '140px', opacity: 1}} className='tab-element'>
                <div>
                    <div className={`tab flex`}>
                        <div className="circle bg-bar"></div>
                        <div className="bar extend bg-bar extend"></div>
                    </div>
                </div>
            </div>
            )
        }
    }

    return (
          <React.Fragment>
          {renderBasedOnSlide()}
          </React.Fragment>
        
    )
}

export default BrowserTab