import React, {useState, useRef} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import { useHistory } from 'react-router';
import './innerpages.css'

function QikfoxBrowserSection() {
    const history = useHistory();
    const [currentIndex, setcurrentIndex] = useState();

    const handleBackButton = () => {
        history.goBack();
    }

    const data = [
        {"id": 1, "title": " What is qikfox Smart Browser?", "content": "qik"},
        {"id": 2, "title": "Why should I pay for Safe Browsing?", "content": "a"},
        {"id": 3, "title":" Why is qikfox Browser blocking my favorite Website?", "content": "a"},
        {"id": 4, "title":" How do I purchase an annual subscription to Qikfox Smart Browser?", "content": "a"},
        {"id": 5, "title":" How do I register and activate my purchase?", "content": "qk"},
        {"id": 6, "title":"What is a qikfox Handle?", "content": "qikfox Handle is your unique identifier on qikfox network universally. The way a twitter handle identifies you on twitter network, similarly qikfox handle identifies you on qikfox browser. Your friends, family and the world can discover you using your qikfox handle.qikfox Handle allows you to browse the web in another unique way - using handles. It replaces the need for registering domain names. Your friends, family and the world can simply find you on the internet using your qikfox handle. For example a user with a handle “foobar” can be easily located by typing “get foobar” in the address bar. To learn more about handles, check out Smart Stacks."}
    ]

    function renderReturn(getID){
        if(getID === 1){
            return(
                <React.Fragment>
                qikfox Smart Browser is a premium chromium based safe web browser that aims to keep consumers safe from online scams, fraudulent websites and other online and file based threats. The browser features numerous privacy, safety and security oriented features like advanced privacy guard, ad and tracker blocking, inbuilt antivirus and a safe search engine. Additionally the browser introduces a brand new way of publishing content and browsing the internet using handles besides conventional location oriented URLs (domains).Future product roadmap features include unified communications, collaboration and safe transactions.
                </React.Fragment>
            )
        }else if(getID === 2){
            return(
                <React.Fragment>Your privacy, safety and security is being compromised by free products. Companies that promote free products generally pay for their services using ads. For targeting ads, they generally need to find ways to grab your data. Something that starts with seemingly mundane requests for data gradually leads to tracking your entire online activity. Before you know your data is being sold to third parties. At best you see annoying ads, and at its worst your privacy is compromised and all your data is littered all over the web. When your data is compromised, you are compromised.As they say, When you don’t pay for the product, you are the product.This statement is best described by the netflix documentary - “The Social Dilemma”. Watch the trailer <a target="_blank" rel="noopener noreferrer" class="faqlink" href="https://www.youtube.com/watch?v=uaaC57tcci0">here</a>.
                The documentary educates the consumers about how big tech uses consumer data to target ads and alter behavior.qikfox Safe Browser prevents your data from being stolen.We are on a a mission to design a trustworthy internet and with every product release, we are determined to enhance consumer privacy, safety and security. 
                </React.Fragment>
            )
        }else if(getID === 3){
            return(
                <React.Fragment>qikfox Smart Browser blocks unreliable websites by default. A website is deemed suspicious or unreliable if the business associated with the website cannot be verified. If your favorite website is being blocked, you can submit a review at <a href="mailto:review@qikfox.com" class="faqlink">site-review@qikfox.com</a>. Our team will verify the business associated with the website and inform you of the decision. Our goal is to keep you safe from fraudulent websites and online scams. Blocking unverified or deceptive websites is a critical component of keeping you safe online. Our goal is to keep you safe. </React.Fragment>
            )
        }else if(getID === 4){
            return(
                <React.Fragment>You can purchase a new subscription from <a target="_blank" rel="noopener noreferrer" class="faqlink" href="https://store.qikfox.com">https://store.qikfox.com</a> or from one of our authorized Distributors or Value Added Resellers. Visit qikfox.com to see the list of partners. </React.Fragment>
            )
        }else if(getID === 5){
            return(
                <React.Fragment>
                When you download and install the qikfox Safe Browser, it automatically takes you to the registration page. Alternatively, you can manually navigate to the registration page by typing qikfox://start in the address bar of your qikfox Safe browser. Once you are on the page, follow the instructions step-by-step. 
                <br></br>
                <br></br>
           <strong> Step 1</strong>: Type in the phone number that you used while purchasing the product. You will receive a 6-digit validation PIN via a text message. Key in the PIN and you will be taken to the next step. Note: qikfox team does not store your phone number. It uses a cryptographic hash of your phone number to validate your subscription. In short, Your privacy is assured. 
             <br></br>
             <br></br>
            <strong>Step 2a</strong>: If you are installing the browser for the first time, then you will be taken to the “Create a New Handle” screen. Here you can create a new alphanumeric handle. A qikfox handle is your anonymous identity on the browser. Your browser profile is associated with this handle. Further, you can use your qikfox handle as a domain name to publish content, besides other uses. 
            <br></br>
            <br></br>
           <strong> Step 2b</strong>: If you already have an existing handle and are simply trying to install qikfox Smart Browser on another computer or reinstall it on the same computer, you will be asked to key in your phone number, like step 1 a PIN will be sent to your phone number and after keying in the correct PIN, the associated handle will be displayed automatically. You can simply select the existing handle and proceed. If you need to disassociate your phone number from a handle, then you will need to disable the handle by calling support. 
           <br></br>
           <br></br>
           <strong> Note</strong>: You can associate only one handle with one phone number but you can configure the same handle on multiple computers using the same handle/phone number combination. 
           <br></br>
           <br></br>
           <strong> Step 3</strong>: Once a new handle is created or an existing one selected, the Registration wizard moves to the next screen - Your Security PIN. You need to set up your security PIN twice to complete the process. 
           <br></br>
           <br></br>
            <strong>Note</strong>: Do not lose the security PIN as this PIN is used to encrypt subscriptions, credentials and the profile. Your browser deployment and related subscriptions will be rendered useless without the PIN. PIN can be recovered using the associated phone number though. 
            <br></br>
            <br></br>
            <strong>Step 4</strong>: Once you establish a security PIN, the browser connects the qikfox Identity issuer and issues subscription cards based on your purchases. Successful completion of the subscription cards results in completion of the process and you are taken to the “Congratulations” page. Your registration of the computer is considered successful. 
            <br></br>
            <br></br>
            <strong>Step 5</strong>: If the registration process fails you can restart the registration process by visiting qikfox://start again. 
                </React.Fragment>
            )
        }else if(getID === 6){
            return(
                <React.Fragment>
                qikfox Handle is your unique identifier on qikfox network universally. The way a twitter handle identifies you on twitter network, similarly qikfox handle identifies you on qikfox browser. Your friends, family and the world can discover you using your qikfox handle.qikfox Handle allows you to browse the web in another unique way - using handles. It replaces the need for registering domain names. Your friends, family and the world can simply find you on the internet using your qikfox handle. For example a user with a handle “foobar” can be easily located by typing “get foobar” in the address bar. To learn more about handles, check out Smart Stacks.
                </React.Fragment>
            )
        }
    }

    return (
        <div className={window.location.pathname === "/support/qikfox-browser" ? "faq-section animate fadeIn five" : "faq-section"}>
        <span onClick={handleBackButton} className="back_button"><img src="/images/support/back-arrow-faq.svg" alt="back-img" /></span>
        <h1 className="faq-heading">Frequently Asked Questions</h1>
        <Accordion className="accordion-main" allowZeroExpanded={true}>
        {data.map((item, index) => (
            <AccordionItem className="accordion-item" uuid={item.id} key={index}>
                <AccordionItemHeading className="accordion-title">
                    <AccordionItemButton className="accordion-title-button">
                    <AccordionItemState>
                    {({ expanded }) => (expanded ? <div><img className="dark-image" src="/images/arrow-down-black.svg" /><div className="text">{item.title}</div></div> : <div><img className="dark-image" src="/images/arrow-up-black.svg" /><div className="text">{item.title}</div></div>)}
                     </AccordionItemState>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-content">
                {renderReturn(item.id)}
              </AccordionItemPanel>
            </AccordionItem>
        ))}
        </Accordion>
        </div>
    )
}

export default QikfoxBrowserSection