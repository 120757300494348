import React from 'react'
import NavigationArrows from './NavigationArrows'

const AddressBar = (props) => {
    return (
        <div className="address-container">
            <div className="flex">
                <NavigationArrows />
                <div className="address-bar bar bg-bar extend"> </div>
                <div className="option bg-bar"> </div>
            </div>
        </div>
    )
}

export default AddressBar