import React from 'react'
import {Link} from 'react-router-dom';


let d = new Date();
let year = d.getFullYear();

function Footer() {
    return (
        <div className="main_footer">
                <div className="footer_main_container_1">
                <div className="content-section-common_1">
                    <div className="Inner-Footer">
                        <div className="Footer-Container">
                            <div className="Footer-item">
                                <div className="Footer-item-inner">
                                    <div className="Footer-item-title">Products</div>
                                    <ul>
                                        <li><a href="/">Safe Web Browser</a></li>
                                        <li><a href="https://search.qikfox.com" target="_blank" rel="noopener noreferrer">Safe Web Search</a></li>
                                        <li><a href="/digital-identity">Identity</a></li>
                                        <li><a href="https://chrome.google.com/webstore/search/qikfox" target="_blank" rel="noopener noreferrer">Chrome Extension</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Footer-item">
                                <div className="Footer-item-inner">
                                    <div className="Footer-item-title">qikfox</div>
                                    <ul>
                                        <li><Link to="/support">Help Center</Link></li>
                                        
                                        <li><Link to="/terms_of_use">Terms of Use</Link></li>
                                        <li><Link to="/privacy">Privacy</Link></li>
                                        <li><Link to="/about-us">About Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-section">
                <div className="footer">
                    <div className="first">
                        <p>Copyright <span>&copy;</span> {year} qikfox Cybersecurity Systems, Inc.</p>
                        <p><a></a></p>
                    </div>
                    <div className="second">
                        {/**<p><a>Get Help</a></p>
                        <p><a>About Us</a></p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer