import React, {useContext, useEffect, useState, useLayoutEffect} from "react"
import {ModalOverlayContext} from "../Home"
import ModalOverlays from "../ModalOverlay"

function BrowserButtons() {
  const getState = useContext(ModalOverlayContext)
  const [deviceTypeClass, setDeviceTypeClass] = useState("")
  const [isDeskTop, setIsDeskTop] = useState(false)

  function detectDeviceType() {
    const userAgent = navigator.userAgent
    let device = ""
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    console.log(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent))
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) && screenWidth<1024) {
      device = "mobile-device"
    } else if (/iPad/i.test(userAgent)) {
      device = "tablet-device"
    } else {
      device = "desktop-device"
      setIsDeskTop(true)
    }
    setDeviceTypeClass(device)
  }

  useLayoutEffect(() => {
    detectDeviceType()
  }, [])

  const downloadQikfoxBrowser = operatingSystemType => {
    if (operatingSystemType === "windows") {
      let a = document.createElement("a")
      a.href = "https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-windows-build.exe"
      a.download = "qikfox-windows"
      document.body.append(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL("https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-windows-build.exe")
    }
    if (operatingSystemType === "mac") {
      let a = document.createElement("a")
      a.href = "https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-mac-intel-build.pkg"
      a.download = "qikfox-mac"
      document.body.append(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL("https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-mac-intel-build.pkg")
    }
    // if (operatingSystemType === 'Mac M1') {
    //   let a = document.createElement('a');
    //   a.href = 'https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-mac-m1-build.pkg';
    //   a.download = 'qikfox-mac';
    //   document.body.append(a);
    //   a.click();
    //   a.remove();
    //   window.URL.revokeObjectURL('https://storage.googleapis.com/qikfox-browser-golden-builds/browser-executables/qik-browser-mac-m1-build.pkg');
    // }
  }

  return (
    <React.Fragment>
      <div className="browser_button_container_outer">
        <div className="browser_button_inner">
          {isDeskTop && <>
          <a
            className={`browser_button_item_outer ${deviceTypeClass}`}
            onClick={() => {
              getState.handleOverlayValue("windows", true)
            }}>
            <div className="browser_button_item_main">
              <div className="browser_button_img" style={{padding: "3px 0"}}>
                <img src="/images/browser_icons/windows_browser.svg" alt="browser_img" width="28px" height="28px" />
              </div>
              <div className="browser_button_text" style={{padding: "1px 0"}}>
                <div>Windows</div>
              </div>
            </div>
          </a>

          <a
            className={`browser_button_item_outer ${deviceTypeClass}`}
            onClick={() => {
              getState.handleOverlayValue("mac", true)
            }}>
            <div className="browser_button_item_main">
              <div className="browser_button_img" style={{padding: "3px 0"}}>
                <img src="/images/browser_icons/apple_browser.svg" alt="browser_img" width="28px" height="28px" />
              </div>
              <div className="browser_button_text" style={{padding: "1px 0"}}>
                <div>Mac</div>
              </div>
            </div>
          </a>
          <a
            className={`browser_button_item_outer ${deviceTypeClass}`}
            onClick={() => {
              getState.handleOverlayValue("linux", true)
            }}>
            <div className="browser_button_item_main">
              <div className="browser_button_img" style={{padding: "3px 0"}}>
                <img src="/images/browser_icons/linux_browser.svg" alt="browser_img" width="28px" height="28px" />
              </div>
              <div className="browser_button_text" style={{padding: "1px 0"}}>
                <div>Linux</div>
              </div>
            </div>
          </a>
          </>}

          <a className={`browser_button_item_outer_mobile ${deviceTypeClass}`} href="https://apps.apple.com/us/app/qikfox/id6444577746" target="_blank">
            <div className="browser_button_item_main_mobile">
              <div className="browser_button_img_mobile">
                <img src="/images/browser_icons/apple_browser.svg" alt="browser_img" 
                // width="28px" height="28px" 
                />
              </div>
              <div className="browser_button_text_mobile">
                {/* <div className="text_upper">Download on the</div> */}
                <div className="text_lower">App Store</div>
              </div>
            </div>
          </a>

          <a className={`browser_button_item_outer_mobile ${deviceTypeClass}`} href="https://play.google.com/store/search?q=qikfox&c=apps" target="_blank">
            <div className="browser_button_item_main_mobile">
              <div className="browser_button_img_mobile">
                <img src="/images/browser_icons/android.svg" alt="browser_img" width="30px" height="32px" />
              </div>
              <div className="browser_button_text_mobile">
                {/* <div className="text_upper">Download on the</div> */}
                <div className="text_lower">Play Store</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BrowserButtons
