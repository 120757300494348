import React, { useState, useRef, useEffect, useContext } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Helmet from 'react-helmet';
import ModalForm from './ModalForm.js'
import { useLocation } from 'react-router-dom';
import { ModalOverlayContext } from './Home.js';

const ModalOverlays = (props) => {

  const { overlay, handleOverlayValue } = useContext(ModalOverlayContext);
  // console.log(getState);

  const location = useLocation();
  const [openWindows, setOpenWindows] = useState(false)
  const [openMac, setOpenMac] = useState(false)
  const [openLinux, setopenLinux] = useState(false)
  const [platform, setplatform] = useState('');
  const [animateCOntainer, setanimateCOntainer] = useState(false);
  const [linuxFInalScreen, setlinuxFInalScreen] = useState(false);

  const myRef = useRef()


  useEffect(() => {
    const timer = setTimeout(() => {
      const el1 = document.querySelector('.header-section-outer');
      let scrollWidth;
      if (overlay.status === false) {
        el1.style.zIndex = 13;
        const timer = setTimeout(() => {
          document.documentElement.style.width = '100%';
          document.documentElement.style.overflow = 'auto';
        }, 500);
        return () => {
          clearTimeout(timer);
        }
      } else if (overlay.status === true) {
        scrollWidth = window.innerWidth - document.documentElement.clientWidth;;
        el1.style.zIndex = 0;
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.width = `calc(100% - ${scrollWidth}px)`;
      }
    }, 5)
    return () => {
      clearTimeout(timer)
    }
  }, [overlay.status]);


  // useEffect(()=>{
  //   if(getState.overlay.flag){
  //     CASE
  //   }else{

  //   }

  // },[getState.overlay])

  const CloseModal = () => {
    handleOverlayValue(overlay.modalType, false)
   setTimeout(() => {
    setlinuxFInalScreen(false);
   }, 500);

    // setOpenWindows(false)
    // setOpenMac(false)
    // setopenLinux(false)
    // setTimeout(() => {
    //   handleOverlayValue(false)
    // }, 500)
  };

  const overLayStatus = (value) => {
    handleOverlayValue(overlay.modalType, value);
  }

  const animateModalCOntainer = (value) => {
    setanimateCOntainer(value)
  }

  const renderLinuxFinalScreen = (value) => {
    setlinuxFInalScreen(value);
  }

  const linuxFInalScreenTemplate = () => {
    return (
      <div className='linux_final_screen_main_container'>
        <div className='linux_final_screen_upper_section'>
          <div className='linux_final_screen_main_heading'>Thank You!</div>
          <div className='linux_final_screen_sub_text'>You have been added to the waitlist.</div>
        </div>
        <div className='linux_final_screen_image_container'>
          <div className='linux_final_screen_image'>
            <img src="/images/thanks_screen.svg" alt="thank_you_img" />
          </div>
        </div>
        <div className='linux_final_screen_button_container'>
          <a className='linux_final_screen_button'>
            <button>Call Now : 1-800-000-3847</button>
          </a>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const el1 = document.querySelector('.header-section-outer');
      if (openMac === false) {
        setplatform();
        el1.style.zIndex = 13;
        //  props.sendMacStatus(false);
      } else if (openMac === true) {
        setplatform("MAC");
        el1.style.zIndex = 0;
        //  props.sendMacStatus(true);

      }
    }, 5)
    return () => {
      clearTimeout(timer)
    }
  }, [openMac]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const el1 = document.querySelector('.header-section-outer');
      if (openWindows === false) {
        setplatform();
        el1.style.zIndex = 13;
        //   props.sendWindowStatus(false);
      } else if (openWindows === true) {
        setplatform("WINDOWS");
        el1.style.zIndex = 0;
        //  props.sendWindowStatus(true);
      }
    }, 5)
    return () => {
      clearTimeout(timer)
    }
  }, [openWindows]);

  return (
    <React.Fragment>
      <Helmet>
        <style>{'html { height: auto; }'}</style>
      </Helmet>


      <div className={`modal_antivirus_help_landing ${overlay.status === true ? 'enter-done' : 'exit'}`}>
        <div className='modal_inner_main_content_browser_type browser_download_overlay_container'>
          <div className="ClosedOverlay"><div className="close-border"><img src="/images/pages/close-icon.svg" alt="close" onClick={CloseModal} /></div></div>
          <div className={linuxFInalScreen !== true ? 'modal-browser' : 'modal-browser linux_final_screen_modal_content'}>
            <div className={animateCOntainer ? "modal-content animateModal fadeIn fiveModal" :'modal-content'}>
              {linuxFInalScreen !== true ?
                <React.Fragment>
                  <div className="modal-left-content">
                    <div className="browser-version-img"><img
                      src={overlay.modalType === 'mac' ? "/images/pages/mac-red.svg" : overlay.modalType === 'windows' ? "/images/pages/windows-red.svg" : "/images/pages/linux-red.svg"}
                      alt={overlay.modalType} /></div>
                  </div>
                  <div className="modal-right-content">
                    <div className="modalb-title">qikfox Safe Browser for {
                      overlay.modalType === 'mac' ? 'MacOS' : overlay.modalType === 'windows' ? 'Windows' : 'Linux'
                    }</div>
                    <div className="modalb-desc">
                      qikfox Safe Browser is an invite-only Web Browser designed to keep you safe on the Internet. The browser comes with advanced safety, security and privacy features that work together to safeguard you from online Scams, Malware, Ad-Ware, Malicious Trackers and more.
                    </div>
                    {/**
                  {overlay.modalType !== 'linux' &&
                      <div className='modalb-desc' style={{ marginTop: '30px' }}>
                        Downloading the browser requires you to verify your phone number using OTP via text message.
                      </div>
                    }
                  */}

                    <ModalForm PlatFORM={platform} closedModal={CloseModal} overlay={overlay.modalType} overLayStatus={overLayStatus}
                      overlayOpenStatus={overlay.status} animateContainer={animateModalCOntainer} animateState={animateCOntainer}
                      renderLinuxFinalScreen={renderLinuxFinalScreen} />
                  </div>
                </React.Fragment> :
                <React.Fragment>
                  {linuxFInalScreenTemplate()}
                </React.Fragment>
              }

            </div>
          </div>
        </div>
      </div>



      {/**<div className="browser-version">
<div className="bversion-items" onClick={() => setOpenWindows(true)}>
<div className="bversion-img"><img src="/images/pages/windows-red.svg" alt="windows"/></div>
<div className="bversion-name">Windows</div>
</div>
<div className="bversion-items" onClick={() => setOpenMac(true)}>
<div className="bversion-img"><img src="/images/pages/mac-red.svg" alt="mac"/></div>
<div className="bversion-name">Mac</div>
</div>

</div> */}


    </React.Fragment>
  );
}

export default ModalOverlays; 