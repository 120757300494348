import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import rootReducer from './root.reducer';

// devtools for debugging in dev environment.
const devTools =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;

const configureStore = (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState
  );
};

const store = configureStore();
export default store;