import React from 'react'
import './TrustworthyInternet.css'

function TrustworthyInternet() {

    const redirectLocation = (cardType) =>{
        switch(cardType){
            case 'card_one':
                window.open('https://qikfox.medium.com/a-case-for-a-brand-new-community-search-engine-to-keep-consumers-safe-55221719da1a');
                break;
            case 'card_two':
                window.open('https://qikfox.medium.com/enhance-consumer-privacy-with-qikfox-smart-browser-a1070004f554');
                break;
            case 'card_three':
                window.open('https://qikfox.medium.com/digital-transformation-for-the-next-billion-e24bfa5d4861');
                break;
            case 'card_four':
                window.open('https://qikfox.medium.com/the-browser-is-the-new-frontline-defense-in-endpoint-security-befce94f077b');
                break;
            default:
                break;
        }
    }



  return (
    <div className='trustworthy_internet_outer animate fadeIn five'>
    <div className='trustworthy_internet_outer_container'>
    <div className='trustworthy_internet_main'>
    <div className='trustworthy_internet_upper_section_card_container'>
    <div className='trustworthy_internet_upper_card_one cardBgImage' style={{backgroundImage: `url("/images/trustworthyInternet/card_one_img.jpg")`}}>
    <div className='card_bg_image'><img src="/images/trustworthyInternet/card_one_img.jpg" alt="img"/></div>
    <div className='trustworthy_internet_card_content_container'>
    <div className='trustworthy_internet_card_date'>June 18, 2021</div>
    <div className='trustworthy_internet_card_content'>
    A case for brand new Community Search Engine
    </div>
    <div className='trustworthy_internet_card_button'>
    <button
    onClick={() => redirectLocation('card_one')}
    >Read Now <span><img src="/images/trustworthy-icon.svg"  alt="arrow"/></span>  </button>
    </div>
    </div>
    </div>
    <div className='trustworthy_internet_upper_card_two cardBgImage' style={{backgroundImage: `url("/images/trustworthyInternet/card_two_img.jpg")`}}>
    <div className='card_bg_image'><img src="/images/trustworthyInternet/card_two_img.jpg" alt="img"/></div>
    <div className='trustworthy_internet_card_content_container'>
    <div className='trustworthy_internet_card_date'>June 18, 2021</div>
    <div className='trustworthy_internet_card_content upper_card_two cardBgImage'>
    World's First Web Browser to enable Decentralized Identity 
    </div>
    <div className='trustworthy_internet_card_button'>
    <button
    onClick={() => redirectLocation('card_two')}
    >Read Now <span><img src="/images/trustworthy-icon.svg"  alt="arrow"/></span> </button>
    </div>
    </div>
    </div>
    </div>

    <div className='trustworthy_internet_lower_section_card_container'>
    <div className='trustworthy_internet_lower_card_one cardBgImage' style={{backgroundImage: `url("/images/trustworthyInternet/card_three_img.jpg")`}}>
    <div className='card_bg_image'><img src="/images/trustworthyInternet/card_three_img.jpg" alt="img"/></div>
    <div className='trustworthy_internet_card_content_container'>
    <div className='trustworthy_internet_card_date'>June 18, 2021</div>
    <div className='trustworthy_internet_card_content'>
    Digital Transformation for the next Billion
    </div>
    <div className='trustworthy_internet_card_button'>
    <button
    onClick={() => redirectLocation('card_three')}
    >Read Now <span><img src="/images/trustworthy-icon.svg"  alt="arrow"/></span> </button>
    </div>
    </div>
    </div>
    <div className='trustworthy_internet_lower_card_two cardBgImage' style={{backgroundImage: `url("/images/trustworthyInternet/card_four_img.jpg")`}}>
    <div className='card_bg_image'><img src="/images/trustworthyInternet/card_four_img.jpg" alt="img"/></div>
    <div className='trustworthy_internet_card_content_container'>
    <div className='trustworthy_internet_card_date'>May 30, 2021</div>
    <div className='trustworthy_internet_card_content'>
    Web Browser is the new Frontline Defense in Endpoint Security
    </div>
    <div className='trustworthy_internet_card_button'>
    <button
    onClick={() => redirectLocation('card_four')}
    >Read Now <span><img src="/images/trustworthy-icon.svg"  alt="arrow"/></span> </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default TrustworthyInternet