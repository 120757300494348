export const pageContent = [
	{heading: "A new era of Internet Browsing has begun.", subText: "We are on a mission to design a Trustworthy Internet. A reliable Internet experience where your safety, privacy and confidentiality are guaranteed. A platform that democratizes internet access for consumers and small businesses alike. The future of smart browsing is here."},
	{heading: "A Safe, Secure and Private Internet", subText: "Consumers are concerned about privacy. Websites steal consumer information without their permission and sell it to third parties. At qikfox, we are on a mission to redesign a next generation internet that is private by design. A comprehensive approach to safety, security and privacy."},
	{heading: "Real-time Protection against Scams", subText: "Online Scams, Fake SSL Certificates, Unverified Online Merchants, Counterfeit Products, Snake Oil Sellers, Fake Ads, Phishing Attacks; Internet has become inherently unsafe. We are on a mission to fix safety and security using next-gen Identity Verification technologies that respect Privacy."},
	{heading: "Foundation for a Smart AI-enabled Internet", subText: "Next generation Internet should be more decentralized, equitable and democratic. The benefits of technologies like Machine Learning, Artificial Intelligence, Blockchain, Decentralized Identity etc should be passed on to Small Businesses and Consumers alike."},
	{heading: "A Safe and User-friendly Decentralized Internet", subText: "Decentralization is being powered by advances in De-Fi, Blockchain, Digital Assets and Peer-to-Peer Technologies. At qikfox, our goal is to democratize participation in decentralized Internet and bring the benefits to the masses by making Web3 User-friendly, Safe, Secure and Reliable."}]


export const identityPageContent = [
	{heading: 'Your Universal Digital Identity', subText: 'Portable, Verifiable, and Universally Accessible digital identity empowers you with unparalleled control and privacy over personal information, while offering seamless integration across a global network of services and applications.'},
	{heading: ' Control your Identity and Data', subText: 'Powered by permissioned and public blockchains, and zero-trust technology, your data and identity is under your control, in your wallet. You are fully in control of what information you share with service providers - public or private.'},
	{heading: 'Secure your digital life with Biometrics', subText: 'Face recognition and biometrics revolutionize decentralized identity, enhancing security by ensuring only the rightful owner can access their digital identity. Worldpass empowers individuals with full control over their personal data, fostering trust and privacy in digital interactions.'}	
]