import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import {decode as base64_decode} from 'base-64';
import './css/style.css'

function ScamAlert() {
    const [SetScamUrl, setSetScamUrl] = useState('');
    const [showCaseURL, setshowCaseURL] = useState('');
    const location = useLocation();

    useEffect(() => {
        getScamUrl();
    }, [location]);

    const getScamUrl = () => {
        var getURL = window.location.href.split("?")[1];
        let decodeUrl;
        if(getURL !== undefined){
            decodeUrl = base64_decode(getURL);
            let removeHTTP = decodeUrl.replace(/^https?\:\/\//i, "");
             let FinalURL =  removeHTTP
             setshowCaseURL(FinalURL); 
            setSetScamUrl(decodeUrl);
        }
    }

    return (
        <div>
        <div class="main-body" data-role="page">
        <div class="main-alert">
       
           <div class="ascam-header">
           <div class="ascam-header-2">
           <div class="ascam-header-2-inner">
           <div class="scammer-img-section"><img src="/images/extension/qf-shapeless-logo.png" class="hacker-img"/></div>
           <div class="hacker-desc">
           <div class="ascam-header-desc">Scam Alert from qikfox</div>
       <div>	We have detected a potential Scam website and have blocked the website 
   from accessing your browser. You have been redirected to qikfox security warning page.
   </div>
   </div>
           </div>
           
           </div>
           
           </div>
           <div class="content-bg-scam">
           <div class="frameTC-bg">
           <div class="content-scam-alert">
           
           <div class="content-scam-1">
           <div class="content-scam-1-inner">

<div class="scam-text-3">
<div class="scamwebsite-title">Website Details:</div>
<div class="scamwebsite-name">{showCaseURL}</div>
</div>
           
           <div class="scam-text-4">
<div class="scamwebsite-threat">Threat Type:</div>
<div class="scamwebsite-threatdetails">Potential Internet Scam</div>
</div>
           
               
           <div class="scam-text-2">Talk to our cyber security experts on how to safeguard your computers, 
tablets, smartphones, and other smart home devices from Scammers.</div>
       <div class="scam-text-1">Call <a href="tel:+1-888-801-1555" class="numberalert">+1-888-801-1555</a> to learn more.</div>
           
           </div>
           </div>
           
           <div class="content-scam-2">
           <input type="hidden" class="websiteredirect" data-role="none" />
           <a href={SetScamUrl} className="safe-browsinglink" data-role="none">
           <div class="scam-continue" id="scamWebvisit">Continue Anyway, I like to be Scammed</div>
           </a>
           <a href="https://www.google.com" class="safe-browsinglink" data-role="none"><div class="resume-safe">Resume Safe Browsing</div></a>
           </div>
       
</div></div>
           </div>

           
        </div>

        </div>
        </div>
    )
}

export default ScamAlert
