import React from 'react'
import { useHistory } from 'react-router-dom';

const CompareFeaturesButton = (props) => {
    let history = useHistory();
    return (
        <div className='compare-features-button' id="compare-features-button"
        onClick={() => {
        history.push(`${props.linkTo}`);
        }}
        >
            {props.label}
        </div>
    )
}

export default CompareFeaturesButton