import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

function Cart(props) {
    const history = useHistory();

    const changeRoute = () => {
        if(props.handlecartdetails.length !== 0){
            history.push('/cart');
        }else{}
    }

    return (
        <div className={props.handlecartdetails.length ? "cart_side_menu active_cart_menu" : "cart_side_menu"}>
        <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
        onClick={() => changeRoute()}>
        <span className="MuiIconButton-label">
            <span className="MuiBadge-root">
            <span><img src="/images/handleAndDomain/cart-icon.png" alt="cart"/></span>
                <span className="MuiBadge-badge MuiBadge-anchorOriginTopRightRectangle MuiBadge-colorSecondary">
                {props.handlecartdetails.length}
                </span>
                </span>
        </span><span className="MuiTouchRipple-root"></span>
        </button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        handlecartdetails: state.handlecartdetails,
    }
}

export default connect(mapStateToProps, null)(Cart)
