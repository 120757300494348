import React from 'react'
import './TopHeader.css';
import {NavLink, useHistory} from 'react-router-dom'

function TopHeader() {
    let history = useHistory();

    return (
        <div className="top_header_main_container">
            <div className="top_header_main_inner">
            <div className="top_header_menu_item_1">
            <div onClick={() => history.push('/')}> <img height="30" width="30" src={process.env.PUBLIC_URL + "/images/qikfox-favicon.svg"} /></div>
            <NavLink className="qik-header-menu-item-heading" to="/">qikfox</NavLink>
            </div>
            <NavLink to="/" exact activeClassName="active-menu" className="top_header_menu_item">The Browser</NavLink>
            <a href="https://search.qikfox.com/" target="_blank" rel="noopener noreferrer" exact activeClassName="active-menu" className="top_header_menu_item">Search Engine</a>
            <NavLink to="/digital-identity" exact activeClassName="active-menu" className="top_header_menu_item">Digital Identity</NavLink>
            <a href="https://chrome.google.com/webstore/search/qikfox" target="_blank" rel="noopener noreferrer" exact activeClassName="active-menu" className="top_header_menu_item">Extensions</a>
            <NavLink to="/trustworthy-internet" activeClassName="active-menu"  className="top_header_menu_item">Trustworthy Internet</NavLink>
            <NavLink to="/about-us" exact activeClassName="active-menu" className="top_header_menu_item">About Us</NavLink>
            <div className="top_header_menu_item">
            <button onClick={() =>  window.open('https://offers.qikfox.com', '_blank', 'noopener,noreferrer')}>Buy</button>
            </div>
            </div>
        </div>
    )
}

export default TopHeader
