import React from 'react'
import { useHistory } from 'react-router';
import './Support.css';

function Support() {
    const history = useHistory();
    const handleNavigationInnerSupportPages = (e) => {
        e.preventDefault();
        let innerPageUrl = e.target.getAttribute('data-link');
        history.push(innerPageUrl);
    }

    return (
        <div className={window.location.pathname === "/support" ? "animate fadeIn five" : ""}>



<div className="media-test"></div>

<div id="announcements">





</div>

<section className="home-search-section sumo-page-section--lg extra-pad-bottom shade-bg" style={{background: '#f7f7f7'}}>
    <div className="mzp-l-content narrow">
        <div className="home-search-section--content">
            <h1 className="sumo-page-heading-xl">qikfox Support</h1>

            <form data-instant-search="form" id="support-search-masthead" action="https://support.mozilla.org/en-US/search" method="get" className="simple-search-form">

                <input spellCheck="true" type="search" autoComplete="off" name="q" aria-required="true" placeholder="Find help..." className="searchbox" id="search-q" />
                <button type="submit" title="Search" className="search-button">Search</button>
            </form>


            <p className="popular-searches">
                <span style={{fontFamily: "Gilroy-Bold", color: '#222222', fontSize: "24px", fontWeight: 'normal', textDecoration: "underline"}}>Get Help:</span>
                <li style={{listStyle: "none", color: "#FF4D41", fontSize: "20px", fontFamily: "Arial-Regular", fontWeight: "bold", display: "contents"}} data-featured-search="">+1-888-801-1555</li>
            </p>
        </div>
    </div>
</section>





<div id="main-content" style={{background: '#f7f7f7'}}>
    <section className="mzp-l-content sumo-page-section--inner">
        <div className="text-center-to-left-on-large">
            <h2 style={{fontFamily: "Gilroy-Bold", fontSize: "24px", fontWeight: 'normal', margin: 0, marginBottom: '32px'}} className="sumo-page-subheading">Pick your Product</h2>
        </div>
        <div className="sumo-card-grid is-product-wrap" >
            <div className="scroll-wrap">

                <div className="card card--product zoom-on-hover">




                    <img className="card--icon" src="/images/support/qikfox_icon_support_page.svg" alt="browser" />
                    <div className="card--details">
                        <h3 className="card--title">
                            <li style={{fontSize: "22px", color: "#55575a", listStyle: "none", cursor: "pointer", fontWeight: "normal", lineHeight: "initial", fontFamily: 'Gilroy-Bold'}}  className="expand-this-link" onClick={handleNavigationInnerSupportPages} data-link="/support/qikfox-browser" data-event-category="link click" data-event-action="product" data-event-label="Firefox">
                                qikfox Smart Browser
          </li>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "14px", lineHeight: '19px', paddingTop: "5px", color: '#55575a'}} className="card--desc">Safe, Fast and Reliable Web Browser for Windows and Mac</p>
                    </div>
                </div>

                <div className="card card--product zoom-on-hover">




                    <img className="card--icon" src="/images/support/antivirus_updated.png" alt="antivirus" />
                    <div className="card--details">
                        <h3 className="card--title">
                            <li style={{fontSize: "22px", color: "#55575a", listStyle: "none", cursor: "pointer", fontWeight: "normal", lineHeight: "initial", fontFamily: 'Gilroy-Bold'}}  className="expand-this-link"onClick={handleNavigationInnerSupportPages} data-link="/support/antivirus" data-event-category="link click" data-event-action="product" data-event-label="Firefox for Android">
                                qikfox Antivirus
          </li>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "14px", lineHeight: '19px', paddingTop: "5px", color: '#55575a'}} className="card--desc">Total Protection from file based and online threats</p>
                    </div>
                </div>

                <div className="card card--product zoom-on-hover">




                    <img className="card--icon" src="/images/support/stacks.png" alt="stacks" />
                    <div className="card--details">
                        <h3 className="card--title">
                            <li style={{fontSize: "22px", color: "#55575a", listStyle: "none", cursor: "pointer", fontWeight: "normal", lineHeight: "initial", fontFamily: 'Gilroy-Bold', marginTop: '10px'}}  className="expand-this-link" onClick={handleNavigationInnerSupportPages} data-link="/support/smart-stacks" data-event-category="link click" data-event-action="product" data-event-label="Firefox for iOS">
            qikfox Smart Stacks
          </li>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "14px", lineHeight: '19px', paddingTop: "5px", color: '#55575a'}} className="card--desc">A brand new way to publish and share content with your friends, family or the entire world</p>
                    </div>
                </div>
        </div>
        </div>
    </section>

    <section  className="mzp-l-content sumo-page-section--inner" >
        <div id="WhitePapers" className="text-center-to-left-on-large">
            <h2 className="sumo-page-subheading">Whitepapers and Articles</h2>
        </div>

        <div className="sumo-card-grid is-four-wide" style={{cgCount: 4}}>
            <div className="scroll-wrap">

                <div className="card card--article zoom-on-hover qikfox_whitepapers_card_container_outer">
                    <img className="card--icon-sm" src="/images/support/support_reader_icon.svg" alt="paper1" />
                    <div className="card--details whitepaper_card_details">
                        <h3 className="card--title">
                            <a  className="expand-this-link" href="https://qikfox.medium.com/a-case-for-a-brand-new-community-search-engine-to-keep-consumers-safe-55221719da1a" target="_blank" rel="noopener noreferrer" data-event-category="link click" data-event-action="product" data-event-label="#Firefox options, preferences and settings">A case for brand new Community Search Engine to keep consumers safe</a>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "15px", lineHeight: "20px", paddingTop: "24px", marginTop: '24px', color: '#55575a', borderTop: '1px solid #8D869A' }} className="card--desc">
                        How can brands, consumers, and content publishers come together to make Web Search safe for consumers? 
                        </p>
                    </div>
                </div>

                <div className="card card--article zoom-on-hover qikfox_whitepapers_card_container_outer">
                    <img className="card--icon-sm" src="/images/support/support_reader_icon.svg" alt="paper2"/>
                    <div className="card--details whitepaper_card_details">
                        <h3 className="card--title">
                            <a className="expand-this-link" href="https://qikfox.medium.com/the-browser-is-the-new-frontline-defense-in-endpoint-security-befce94f077b" target="_blank" rel="noopener noreferrer" data-event-category="link click" data-event-action="product" data-event-label="#How to change your default browser in Windows 10">Web Browser is the New Frontline Defense in Endpoint Security</a>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "15px", lineHeight: "20px", paddingTop: "24px", marginTop: '24px', color: '#55575a', borderTop: '1px solid #8D869A' }} className="card--desc">
                        How a smart Internet Browser can play a central role in safeguarding consumers from online threats? 	
                        </p>
                    </div>
                </div>

                <div className="card card--article zoom-on-hover qikfox_whitepapers_card_container_outer">
                    <img className="card--icon-sm" src="/images/support/support_reader_icon.svg" alt="paper3" />
                    <div className="card--details whitepaper_card_details">
                        <h3 className="card--title">
                            <a className="expand-this-link" href="https://qikfox.medium.com/digital-transformation-for-the-next-billion-e24bfa5d4861" target="_blank" rel="noopener noreferrer" data-event-category="link click" data-event-action="product" data-event-label="#Update Firefox to the latest version">Digital Transformation for the next Billion</a>
                        </h3>
                        <p style={{fontFamily: 'Muli-Regular', fontSize: "15px", lineHeight: "20px", paddingTop: "24px", marginTop: '24px', color: '#55575a', borderTop: '1px solid #8D869A' }} className="card--desc">
                        How Smart Stacks make the web more participatory and democratic using zero-code content publishing and universally discoverable handles
                        </p>
                    </div>
                </div>
                <div className="card card--article zoom-on-hover qikfox_whitepapers_card_container_outer">
                <img className="card--icon-sm" src="/images/support/support_reader_icon.svg" alt="paper4" />
                <div className="card--details whitepaper_card_details">
                    <h3 className="card--title">
                        <a className="expand-this-link" href="https://qikfox.medium.com/enhance-consumer-privacy-with-qikfox-smart-browser-a1070004f554" target="_blank" rel="noopener noreferrer" data-event-category="link click" data-event-action="product" data-event-label="#Update Firefox to the latest version">World’s first Web Browser to enable Decentralized Identity </a>
                    </h3>
                    <p style={{fontFamily: 'Muli-Regular', fontSize: "15px", lineHeight: "20px", paddingTop: "24px", marginTop: '24px', color: '#55575a', borderTop: '1px solid #8D869A' }} className="card--desc">
                    qikfox Smart Browser becomes the first Web Browser to integrate and enable decentralized identity. A decentralized identity system works to enhance consumer privacy, safety and security.
                    </p>
                </div>
            </div>

            </div>
        </div>

    </section>
</div>



        </div>
    )
}

export default Support