import React from 'react'
import './App.css';
import {
  BrowserRouter as Router
} from "react-router-dom";
import Routes from './Route.js/Route'
import Footer from './Footer/Footer';
import './Footer/Footer.css'
import ScrollToTop from './ScrollToTop/ScrollToTop';
import MainNavigationContainer from './NavigationBar/MainNavigationContainer';
import { BrowserViewProvider } from './ContextProviders/BrowserViewContext';


function App(props) {

  // useEffect(() =>{
  //   console.log("build 12");
  // },[]);

  return (
    <div className="App">
      <Router>
    <ScrollToTop>
    <MainNavigationContainer/>
    <Routes />
    <Footer />
    </ScrollToTop>
    </Router>
    </div>
  );
}

export default App;
