import React from 'react'
import './Section5.css'

function Section5() {
  return (
    <div className="br-section-3">
        <div className='content-section-common identity_section_5_inner'>
    <div className="identity_pg_section_5_container">
      <div className="identity_pg_section_5_header">
        <div className="section_5_header_text">
          Decentralized Identity
        </div>
        <div className="section_5_header_border_box">
        </div>
      </div>
  
      <div className="identity_pg_section_5_content_upper">
        <div className="content_inner_container">
        <div className="content_inner_item">
            <div className="item_img">
              <img src="/images/identity/infographic-verify-icon-01.svg" />
            </div>
            <div className="item_text">
              <div
              className="item_text_inner"
              >VERIFY</div>
            </div>
  
            <div className="item_cards_container">
              <div className="item_card">
                <div>Biometrics</div>
              </div>
              <div className="item_card">
                <div>ID Proofing</div>
              </div>
              <div className="item_card">
                <div>Verified Credentials</div>
              </div>
            </div>
  
          </div>
          <div className="content_inner_item">
            <div className="item_img">
              <img src="/images/identity/infographic-authenticate-icon-01.svg" />
            </div>
            <div className="item_text">
              <div
              className="item_text_inner"
              >AUTHENTICATE</div>
            </div>
  
            <div className="item_cards_container">
              <div className="item_card">
                <div>Intelligent Authentication</div>
              </div>
              <div className="item_card">
                <div>Software Authentication</div>
              </div>
              <div className="item_card">
                <div>Biometrics, IAM Integration</div>
              </div>
            </div>
  
          </div>
          <div className="content_inner_item">
            <div className="item_img">
              <img src="/images/identity/infographic-interact-icon-01.svg" />
            </div>
            <div className="item_text">
              <div
              className="item_text_inner"
              >INTERACT</div>
            </div>
  
            <div className="item_cards_container">
              <div className="item_card">
                <div>Declarative Workflows</div>
              </div>
              <div className="item_card">
                <div>Multichannel Notifications</div>
              </div>
              <div className="item_card">
                <div>Audit Trail</div>
              </div>
            </div>
  
          </div>
          <div className="content_inner_item">
            <div className="item_img">
              <img src="/images/identity/infographic-transact-icon-01.svg" />
            </div>
            <div className="item_text">
              <div
              className="item_text_inner"
              >TRANSACT</div>
            </div>
  
            <div className="item_cards_container">
              <div className="item_card">
                <div>Verifiable Signatures</div>
              </div>
              <div className="item_card">
                <div>Immutable Transactions</div>
              </div>
              <div className="item_card">
                <div>Standards-based Interactions</div>
              </div>
            </div>
  
          </div>
          <div className="content_inner_item">
            <div className="item_img">
              <img src="/images/identity/infographic-vault-icon-01.svg" />
            </div>
            <div className="item_text">
              <div
              className="item_text_inner"
              >VAULT</div>
            </div>
  
            <div className="item_cards_container">
              <div className="item_card">
                <div>Trust Registry</div>
              </div>
              <div className="item_card">
                <div>Encrypted Vault</div>
              </div>
              <div className="item_card">
                <div>Storage Connectors</div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
  
      <div className="identity_pg_section_5_content_mid">
        <div className="section_5_header_border_box_bottom">
        </div>
  
        <div className="identity_pg_section_5_content_mid_separator_outer">
          <div className="identity_pg_section_5_content_mid_separator_inner">
            <div className="identity_pg_section_5_content_mid_separator"></div>
            <div className="identity_pg_section_5_content_mid_separator_bottom"></div>
          </div>
        </div>
  
        <div className="content_lower_cards">
          <div className="lower_section_item">
            <div className="lower_section_text">
              <div>AI-Enabled Interactions</div>
              <div></div>
              <div>Immutable Ledger</div>
              <div></div>
              <div>Standardized Communications</div>
              <div></div>
              <div>Quantum-Resistant Security</div>
            </div>
          </div>
        </div>
  
        <div className="content_lower_cards_bottom">
          <div className="lower_section_item_bottom">
            <div className="lower_section_text_bottom">
              <div>Universal Registrars</div>
              <div className="round"></div>
              <div>Universal Resolvers</div>
              {/* <div className="round"></div> */}
              <div className="text_bottom_img">
                <img src="/images/identity/word_pass_identity.PNG" />
              </div>
              <div>Zero-Trust Verifiers</div>
              <div className="round"></div>
              <div>No-Code Governance</div>
            </div>
          </div>
        </div>
  
      </div>
  
  
    </div>
    </div>
  </div>
  
  )
}


export default Section5