import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import { useHistory } from 'react-router';
import './innerpages.css'

function AntivirusSection() {
    const history = useHistory();

    const handleBackButton = () => {
        history.goBack();
    }


    const data = [
        {"id": 0, "title": "What is Full Scan?", "content": "Full Scan is used to scan all the volumes (hard disks) for various threats. Each file is deep scanned and appropriate action is taken. Infected files are either cleaned (fixed) or quarantined. Full Scan can take from 30 minutes to a few hours depending on the number of files to be scanned. The speed of the scan also depends on the RAM (Random Access Memory) and processing cores available to the antivirus service. Qikfox Antivirus can run multiple scans in parallel to speed up the full scan."},
        {"id": 1, "title":" What is Smart Scan?", "content": "Smart Scan is also called the “Delta Scan”. It scans the modified, opened or new files on your system. Generally the viruses enter the system when a new file is downloaded, an existing file opened or modified. Smart Scan targets these files. Smart Scan is faster than the Full Scan as it does not scan all the files on your computer system."},
        {"id": 2, "title":" What is the “Scan Downloads” option?", "content": "“Scan Downloads” option can be used to scan your common folders where you download files or is your active working folder. You can configure up to five folders to run a quick scan."},
        {"id": 3, "title":"  How do I keep my virus definitions up to date?", "content": "New virus definitions are updated automatically. If the home page shows a label “Up to Date”. This means the virus definitions are up to date."},
        {"id": 4, "title":" What is scan history?", "content": "Scan history button can be clicked to navigate to see basic scan history reports. The reports give you information about the scan type, threats, and scan duration."},
        {"id": 5, "title":"My antivirus is not working properly. What can I do?", "content": "You can call 1-800-418-2917 or send an email to support@qikfox.com and a qikfox antivirus expert will call you within 2 hours."},
        {"id": 6, "title": "Is the antivirus part of qikfox browser?", "content": "Yes, the antivirus software is an integral part of qikfox browser’s “Cybersecurity Suite” that includes antivirus service, ad-blocker, and integrated safe search engine."},
        {"id": 7, "title":" How can I renew my subscription?", "content": "Antivirus subscription can be renewed automatically along with annual qikfox subscription. Please call tel:1-800-418-2917 or send an email to mailto:support@qikfox.com to renew the subscription. Alternatively you can reach out to the partner/reseller from where you purchased the subscription. Qikfox Browser or Antivirus Subscription does not renew automatically."},
    ]

    function renderReturn(getID){
        if(getID !== 7 &&getID !== 5){
            return(
                <React.Fragment>
                {data[getID].content}
                </React.Fragment>
            )
        }else if(getID === 7){
            return(
                <React.Fragment>Antivirus subscription can be renewed automatically along with annual qikfox subscription. Please call <a href="tel:1-800-418-2917" class="faqlink">1-800-418-2917</a> or send an email to <a href="mailto:support@qikfox.com" class="faqlink">support@qikfox.com</a> to renew the subscription. Alternatively you can reach out to the partner/reseller from where you purchased the subscription. Qikfox Browser or Antivirus Subscription does not renew automatically.</React.Fragment>
            )
        }else if(getID === 5){
            return(
                <React.Fragment>You can call <a href="tel:1-800-418-2917" class="faqlink">1-800-418-2917</a> or send an email to <a href="mailto:support@qikfox.com" class="faqlink">support@qikfox.com</a> and a qikfox antivirus expert will call you within 2 hours.</React.Fragment>
            )
        }
    }

    return (
        <div className={window.location.pathname === "/support/antivirus" ? "faq-section animate fadeIn five" : "faq-section"}>
        <span onClick={handleBackButton} className="back_button"><img src="/images/support/back-arrow-faq.svg" alt="back-img" /></span>
        <h1 className="faq-heading">Frequently Asked Questions</h1>
        <Accordion className="accordion-main" allowZeroExpanded={true}>
        {data.map((item, index) => (
            <AccordionItem className="accordion-item" uuid={item.id} key={index}>
                <AccordionItemHeading className="accordion-title">
                    <AccordionItemButton className="accordion-title-button">
                    <AccordionItemState>
                    {({ expanded }) => (expanded ? <div><img className="dark-image" src="/images/arrow-down-black.svg" /><div className="text">{item.title}</div></div> : <div><img className="dark-image" src="/images/arrow-up-black.svg" /><div className="text">{item.title}</div></div>)}
                     </AccordionItemState>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-content">
                {renderReturn(item.id)}
                </AccordionItemPanel>
            </AccordionItem>
        ))}
        </Accordion>
        </div>
    )
}

export default AntivirusSection