import React, { Component } from 'react';
import Select from 'react-select';
import Loader from './loaderComponent/Loader';
import OtpInput from 'react-otp-input';
import SelectCountryFlag from '../components/SelectCountryFlag';
import { browserDownloadLink } from '../Config/browserDownloadLink';


let FormdataArray = {
  FullName: "",
  Email: "",
  Number: "",
  osType: '',
}

const optionsWindows = [
  { value: 'Windows 10', label: 'Windows 10' },
  { value: 'Windows 11', label: 'Windows 11' }]

const optionsMac = [
  { value: 'MacOS Intel', label: 'MacOS Intel' },
  { value: 'MacOS M1', label: 'MacOS M1' },
  { value: 'MacOS M2', label: 'MacOS M2' }
]

const optionsLinux = [
  { value: 'Linux Ubuntu', label: 'Linux Ubuntu' },
  { value: 'Linux Debian', label: 'Linux Debian' },
  { value: 'Linux PureOS', label: 'Linux PureOS' }]

const windowsPlaceholder = "Choose the Windows Version"
const macPlaceholder = "Choose the MacOS Version"
const linuxPlaceholder = "Choose the Linux Distro"

class ModalForm extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    // console.log(this.props)
  }


  state = {
    formData: FormdataArray,
    errors: { title: "", DateScan: "", TimeScan: "" },
    invalid: false,
    SucessMsg: '',
    SucessMsgFadeout: false,
    Showloader: false,
    responseMessage: '',
    browserOSType: { placeholder: windowsPlaceholder, options: optionsWindows },
    renderOTPFormComponent: false,
    loaderState: false,
    otpValue: parseInt(''),
    sToken: '',
    otpSuccessText: '',
    isOTPVerified: false,
    resendOTPText: '',
    phoneNumberValidError: '',
    platFormType: '',
    isLinuxFinalScreen: false,
    countryCode:{"countryName":"United States","countryCode":"1","countryFlag":"United_States_01.svg"}
  }

  componentDidMount() {
   
    //this.resetFormState();
    //this.overlayBasedREnder();
  }

  overlayBasedREnder(overlayType) {
    console.log(this.state.formData.osType.length)
    if(this.state.formData.osType.length === 0 ){
    if (overlayType === 'windows') {
      this.setState({ browserOSType: { placeholder: windowsPlaceholder, options: optionsWindows } })
    }
    if (overlayType === 'mac') {
      this.setState({ browserOSType: { placeholder: macPlaceholder, options: optionsMac } })
    }
    if (overlayType === 'linux') {
      this.setState({ browserOSType: { placeholder: linuxPlaceholder, options: optionsLinux } })
    }
  }
  }



  componentWillReceiveProps(props) {
    this.setState({platFormType: props.overlay});
    //console.log(props)
    //   console.log(this.props)
    // console.log('receive prop', overlayOpenStatus);
    if (props.overlayOpenStatus === false) {
      setTimeout(() => {
        this.resetFormState();
      }, 500);
      //this.overlayBasedREnder();
    } else {
      this.overlayBasedREnder(props.overlay);
    }

  }


  handleSubmit = (event) => {
    event.preventDefault();
    // this.setState({SucessMsgFadeout:false})
    // if (this.Validate()) {
    //   } else {
    //     let validNumberString = this.state.formData.Number.split(/\s/).join('').replace(/[\D]/g, '');
    //     console.log(validNumberString);
    //    // console.log(validNumberString.length);
    //     if(validNumberString.length > 10){
    //       validNumberString = validNumberString.substring(1);
    //       //console.log(validNumberString);
    //     }
    //     if(validNumberString.length === 10){
    //       //console.log(validNumberString);
    //     }
    //     // console.log(validNumberString);
    //     this.setState({responseMessage: 'Please Wait',SucessMsgFadeout:true,Showloader:false});
    //     const data = {
    //       "name": this.state.formData.FullName,
    //       "email": this.state.formData.Email,
    //       "number": validNumberString,
    //       "platform": this.props.PlatFORM
    //     }
    //      //console.log(data);
    //     let rege = /[A-Za-z]/
    //     // console.log(rege.test(this.state.formData.Number));
    //     fetch('https://emailgateway-dot-qikfox-website-test-project.wl.r.appspot.com/sendinvite',{
    //       mode: 'cors',
    //       method: 'POST',
    //       headers: {
    //           'Content-Type': 'application/json',
    //           'Accept': 'application/json',
    //           'Access-Control-Allow-Origin': '*'
    //       },
    //       body: JSON.stringify(data)
    //   }).then(res => res.json())
    //   .then((response) => {
    //     if(response.status === 1){
    //       this.setState({responseMessage: '', SucessMsgFadeout:true,Showloader:false});
    //       this.SuceesMsgFadeOut();
    //       this.setState({formData:FormdataArray,SucessMsg:"Thank you for signing up",SucessMsgFadeout:true,Showloader:false});
    //       this.SuceesMsgFadeOut();
    //     }else{
    //       this.setState({responseMessage: '', SucessMsgFadeout:true,Showloader:false});
    //       this.SuceesMsgFadeOut();
    //      this.setState({formData:FormdataArray,SucessMsg:"Error in submitting form. Try again Later",SucessMsgFadeout:true,Showloader:false});
    //      this.SuceesMsgFadeOut();
    //     }
    //   }).catch((err) => {
    //     if(err){
    //       this.setState({responseMessage: '', SucessMsgFadeout:true,Showloader:false});
    //       this.SuceesMsgFadeOut();
    //       this.setState({formData:FormdataArray,SucessMsg:"Error in submitting form. Try again Later",SucessMsgFadeout:true,Showloader:false});
    //       this.SuceesMsgFadeOut();
    //     }
    //   });
    //   }

  }

  //  SaveEntry=()=>{
  //   setTimeout(() => {
  //       this.NameInput.focus();
  //       this.setState({formData:FormdataArray,SucessMsg:"Thank you for signing up",SucessMsgFadeout:true,Showloader:false})
  //       this.SuceesMsgFadeOut();
  //     }, 1);

  //  }

  //  SuceesMsgFadeOut=()=>{
  //   setTimeout(() => {
  //     this.setState({SucessMsgFadeout:false})
  //     this.props.closedModal(); 
  //   }, 2500);
  // }


  Validate = () => {
    this.setState({phoneNumberValidError: ''})
    const { formData } = this.state;
    const { FullName, Email, osType } = formData;
    const errors = {}
    let invalid = false;
    let Number = this.state.formData.Number.split(/\s/).join('');
    let NameregularExp = /^[A-Za-z]+$/;
    let rege = /[A-Za-z]/

    if(this.state.platFormType === 'linux'){
      if (osType === "") {
        errors.selectInput = "Please select a OS version.";
        this.selectInputRef.focus();
        invalid = true;
      }
      else if (FullName.length === 0) {
        errors.FullName = "Please enter the Full Name.";
        this.NameInput.focus();
        invalid = true;
      }
      else if (Number.length === 0) {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (rege.test(Number) === true) {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.length > 16 && Number.charAt(0) === '+' && Number.charAt(1) === "1"
        && Number.charAt(2) === '(' && Number.charAt(6) === ')' &&
        Number.charAt(7) === '-' && Number.charAt(11) === '-') {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.length > 15 && Number.includes('+') !== true && Number.charAt(0) === "1" && Number.charAt(1) === '('
        && Number.charAt(4) === '-' && Number.charAt(5) === ')' && Number.charAt(8) === '-') {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.length > 14 && Number.charAt(0) === '+' && Number.charAt(1) === "1" &&
        Number.charAt(5) === '-' && Number.charAt(9) === '-' && Number.includes('(') !== true && Number.includes(')') !== true ||
        Number.length > 14 && Number.charAt(0) === '+' && Number.charAt(1) === "1" &&
        Number.includes('-') !== true && Number.charAt(2) === '(' && Number.charAt(6) === ')') {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.charAt(1) === "(" && Number.charAt(5) === ")" && Number.includes('-') !== true) {
        invalid = false;
      }
      else if (Number.length > 12 && Number.charAt(0) === '+' && Number.charAt(1) === "1" &&
        Number.includes('-') !== true && Number.includes('(') !== true && Number.includes(')') !== true
        || Number.length > 12 && Number.includes('+') !== true
        && Number.charAt(3) === '-' && Number.charAt(7) === '-'
        || Number.length > 12 && Number.includes('+') !== true && Number.includes('-') !== true
        && Number.includes('(') === true && Number.includes(')') === true
      ) {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.length > 11 && Number.includes('+') !== true &&
        Number.includes('-') !== true && Number.includes('(') !== true && Number.includes(')') !== true) {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      else if (Number.length < 10) {
        errors.NumberInput = "Please enter a Valid number.";
        this.NumberInput.focus();
        invalid = true;
      }
      /*else if (!NameregularExp.test(FullName)) {
          errors.FullName="Please enter the valid Full Name.";
          this.NameInput.focus();
          invalid = true;
      }*/
  
      //   else if (Email.length === 0) {
      //       errors.Email="Please enter an Email.";
      //       this.EmailInput.focus();
      //       invalid = true;
  
      //   }
      //    else if (!this.ValidateEmail(Email)) {
      //   errors.Email="Please enter a valid Email.";
      //   this.EmailInput.focus();
      //   invalid = true;
      //  }	
    }else{
      if (osType === "") {
        errors.selectInput = "Please select a OS version.";
        this.selectInputRef.focus();
        invalid = true;
      }
    }
    this.setState({
      errors, invalid
    })

    return invalid;

  }

  ValidateEmail = (email) => {
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return expr.test(email)
  };


  handleChangeValue = event => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData, // leave other values unchanged
        [event.target.name]: event.target.value, // update the changed value
      }
    });
  }

  onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("select_inner_antivirus__option--is-selected")[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ block: 'nearest', inline: 'start' });
      }
    }, 0);
  };

  handleVersionSelect = (selectedOption) => {
    const { formData } = this.state;
    if (selectedOption) {
      let FormDataCopy = { ...formData };
      FormDataCopy['osType'] = selectedOption.value;
      this.setState({ formData: FormDataCopy })
    }
  }

  flagSelected=(getFlag)=>{
    this.setState({countryCode:getFlag})
  }

  generateOTP = async(e) => {
    e.preventDefault();
    if(this.state.platFormType === 'linux'){
      if (this.Validate()) {
        return true
      }else{
        this.setState({ loaderState: true});
        this.sendZohoData();
        return true
      }
    }
    if (this.state.isOTPVerified === false) {
      e.preventDefault();
      if (this.Validate()) {

      } else {
        let number = "+" + this.state.countryCode.countryCode + this.state.formData.Number
        this.setState({ loaderState: true });
        const data = { phoneNumber: number }
        fetch('https://smsapi.qikfox.com/smsgateway/generate/otp', {
          method: 'POST',
          headers: {
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(data)
        }).then((res) => {
          console.log(res);
          if (res.status == 200) {
            return res;
          }else if(res.status == 406){
            this.setState({loaderState: false,
              phoneNumberValidError: 'Phone Number not found. Please enter a valid Phone Number.' });
          }
           else {
            console.log(res.status);
            this.setState({ renderOTPFormComponent: false, loaderState: false,
              phoneNumberValidError: 'Please try again!' });
          }
        })
          .then((response) => {
            console.log(response);
            // console.log(sToken);
            if (response) {
              let sToken = response.headers.get('s-tok')
              this.props.animateContainer(true);
              this.setState({
                renderOTPFormComponent: true, loaderState: false, sToken: sToken,
                isOTPVerified: true, phoneNumberValidError: ''
              });
            } else {
              this.setState({ renderOTPFormComponent: false, loaderState: false,
                phoneNumberValidError: 'Phone Number not found. Please enter a valid Phone Number.'});
            }
          }).catch((err) => {
            console.log(err)
            this.setState({ renderOTPFormComponent: false, loaderState: false, 
              phoneNumberValidError: 'Please try again!' });
          });
      }
    }
    else {
      e.preventDefault();
      await this.downloadBrowser();
      this.props.overLayStatus(false);
      setTimeout(() => {
        this.resetFormState();
      }, 500);
    }
  }


  handleOTP = (otp) => {
    console.log(otp);
    this.setState({ ...this.state, otpValue: otp });
  }

  verifyOTP = (e) => {
    e.preventDefault();
    console.log(this.state.otpValue.length)
    if (this.state.otpValue.length < 6 || this.state.otpValue.length === undefined) {
      // console.log("otp value length is less than 6")
    } else {
      this.props.animateContainer(false);
      //  console.log("greater than 6")
      this.setState({ loaderState: true, otpSuccessText: '', resendOTPText: '' });
      const data = { "activationCode": this.state.otpValue }
      fetch('https://smsapi.qikfox.com/smsgateway/verify/otp', {
        method: 'POST',
        headers: {
          'accept': '*/*',
          'Access-Control-Allow-Origin': '*',
          's-tok': this.state.sToken
        },
        body: JSON.stringify(data)
      }).then((res) => {
        if (res.status === 200) {
          console.log('res', res);
          this.sendZohoData();
          // this.downloadBrowser();
          // this.props.overLayStatus(false);
          // setTimeout(() => {
          //   this.resetFormState();
          // }, 500);
        } else {
          this.setState({ loaderState: false, otpSuccessText: 'Invalid OTP' });
        }
      })
    }
  }

  resetFormState = () => {
    this.setState({
      formData: FormdataArray,
      errors: { title: "", DateScan: "", TimeScan: "" },
      invalid: false,
      isOTPVerified: false,
      SucessMsg: '',
      SucessMsgFadeout: false,
      Showloader: false,
      responseMessage: '',
      browserOSType: { placeholder: "", options: {} },
      renderOTPFormComponent: false,
      loaderState: false,
      otpValue: parseInt(''),
      sToken: '',
      otpSuccessText: '',
    });
  }


  resendOTP = () => {
    this.setState({otpSuccessText: ''});
    let number = "+" + this.state.countryCode.countryCode + this.state.formData.Number
    // this.setState({loaderState: true});
    const data = { phoneNumber: number }
    fetch('https://smsapi.qikfox.com/smsgateway/generate/otp', {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    }).then((res) => {
      if (res.status === 200) {
        return res;
      } else {
        // this.setState({renderOTPFormComponent: false, loaderState: false});
      }
    })
      .then((response) => {
        let sToken = response.headers.get('s-tok')
        // console.log(sToken);
        if (sToken !== undefined) {
          this.setState({ sToken: sToken, resendOTPText: 'OTP Sent!', otpValue: parseInt('') });
        } else {
          //  this.setState({renderOTPFormComponent: false, loaderState: false});
        }
      }).catch((err) => {
        // this.setState({renderOTPFormComponent: false, loaderState: false});
      });
  }


  otpComponent = () => {
    return (
      <div className='otp_screen_main_container'>
        <div className='otp_screen_main_heading'>
          Confirm Pin
        </div>
        <div className='otp_screen_main_content'>
          <form className='browser_input_form_inner'
            onSubmit={(e) => this.verifyOTP(e)}>
            {/** <p className="validation_error">{verifyOTPError}</p>
     <p className='validation_error'> {InvalidOTPError}</p> */}
            <OtpInput
              value={this.state.otpValue}
              onChange={this.handleOTP}
              numInputs={6}
              separator={false}
              isInputNum={true}
              isDisabled={this.state.loaderState}
              inputStyle='browser_otp_input_field'
              containerStyle='browser_otp_input_container'
            />
            {this.state.otpSuccessText !== '' ? <div className='otp_success_text'>{this.state.otpSuccessText}</div> : ''}
            {this.state.resendOTPText !== '' ? <div className='resend_success_text'>{this.state.resendOTPText}</div> : ''}
           <div style={{maxWidth: '200px', margin: '0 auto'}}>
           <button className="done-button"
           disabled={this.state.loaderState}
           onClick={(e) => this.verifyOTP(e)}>{
             this.state.loaderState === true ? <Loader /> : 'Verify'
           }</button>
           </div>
            <div className='resend_code_text'
              onClick={() => this.resendOTP()}
            >Resend Code</div>
          </form>
        </div>
      </div>
    )
  }

  downloadMainController = async(e) =>{
    e.preventDefault();
    if (this.Validate()) {
      return true
    }else{
      await this.downloadBrowser();
      this.props.overLayStatus(false);
      setTimeout(() => {
        this.resetFormState();
      }, 500);
    }
  }


  downloadBrowser = async() => {
    let browserBuildData
   await fetch(browserDownloadLink)
    .then((res) =>
    res.json()
    ).then((result) =>{
    console.log('result', result)
    browserBuildData = result
  }).catch((err) =>{
    console.log('err')
  })

  let windows
  let macIntel
  let macM1
  if(browserBuildData && browserBuildData.length == 3){
    windows = browserBuildData.find((object) => object.hasOwnProperty("windowsBuild"));
    macM1 = browserBuildData.find((object) => object.hasOwnProperty("macM1Build"));
    macIntel = browserBuildData.find((object) => object.hasOwnProperty("macIntelBuild"));
  }

  console.log(windows)
  console.log(macIntel)
  console.log(macM1)


  let a = document.createElement('a');
  console.log(this.props.overlay)
    if (this.props.overlay === 'windows') {
      a.href =  windows.windowsBuild;
      a.download = windows.buildName
      console.log('a', a)
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(windows.windowsBuild);

    }
    if (this.props.overlay === 'mac') {
      if (this.state.formData.osType === 'MacOS Intel') {
        a.href = macIntel.macIntelBuild;
        a.download = macIntel.buildName
        console.log('a', a)
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(macIntel.macIntelBuild);
      }
      if (this.state.formData.osType === 'MacOS M1' || this.state.formData.osType === 'MacOS M2') {
        a.href = macM1.macM1Build;
        a.download = macM1.buildName
        console.log('a', a)
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(macM1.macM1Build);
      }
    }
  }


  sendZohoData = () => {
    const data = {
      "name": this.state.formData.FullName,
      "phone": '+1' + this.state.formData.Number.replace(/-/g, ''),
      "antivirus_used": "none",
      "is_verified": this.state.platFormType === 'linux' ? "false" : "true",
      "operating_system": this.state.formData.osType,
      "lead_type":  this.state.platFormType !== 'linux' ? "browser download" : "linux waitlist"
    }
    console.log(data);
    fetch('https://crmapi.qikfox.com/add-lead', {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        console.log(this.state.formData.osType);
        if(this.state.platFormType === 'linux'){
          this.props.animateContainer(true);
          this.setState({ loaderState: false});
          this.props.renderLinuxFinalScreen(true);
        }else{
          if (res.status == 200) {
            this.props.animateContainer(true);
            this.setState({ loaderState: false, renderOTPFormComponent: false });
          }
          else {
            this.props.animateContainer(true);
            this.setState({ loaderState: false, renderOTPFormComponent: false });
          }
        }
      })
      .then(resp => {
        if (resp) {
        }
      }).catch((err) =>{
        if(this.state.platFormType === 'linux'){
          this.props.animateContainer(true);
          this.setState({ loaderState: false});
          this.props.renderLinuxFinalScreen(true);
        }else{
          this.props.animateContainer(true);
          this.setState({ loaderState: false, renderOTPFormComponent: false });
        }
      })
  }

  render() {



    return (

      <>
        <div className={this.state.platFormType !== 'linux' ?"modalb-form modal_form_height"  : "modalb-form"}>
          <form onSubmit={(event) => this.downloadMainController(event)}>
            {this.state.errors.FullName && <div className="errMsg">{this.state.errors.FullName}</div>}
            {this.state.errors.Email && <div className="errMsg">{this.state.errors.Email}</div>}
            {this.state.errors.NumberInput && <div className="errMsg">{this.state.errors.NumberInput}</div>}
            {this.state.errors.selectInput && <div className="errMsg">{this.state.errors.selectInput}</div>}
            {this.state.phoneNumberValidError !== '' ? <div className='errMsg'>{this.state.phoneNumberValidError}</div> : ''}
            {/**<div className={this.state.SucessMsgFadeout ? 'Smsg Msg-fadein' :'Smsg Msg-fadeout'}>{this.state.responseMessage}</div>
<div className={this.state.SucessMsgFadeout? 'Smsg Msg-fadein' :'Smsg Msg-fadeout'}>
            {this.state.SucessMsg} 
            </div> */}

            {this.state.renderOTPFormComponent === false ?
              <React.Fragment>
                {this.state.browserOSType.placeholder && <Select
                  className="select_container_antivirus"
                  classNamePrefix="select_inner_antivirus"
                  options={this.state.browserOSType.options}
                  placeholder={this.state.browserOSType.placeholder}
                  value = {
                    this.state.browserOSType.options.filter(option => 
                       option.label === this.state.formData.osType)
                 }
              
                  isSearchable={false}
                  onChange={this.handleVersionSelect}
                  onMenuOpen={this.onMenuOpen}
                  isDisabled={this.state.loaderState === true || this.state.isOTPVerified === true}
                  // isDisabled={loader}
                  ref={(input) => { this.selectInputRef = input }}
                /*menuIsOpen={true}*/
                />}

               {this.state.platFormType !== 'linux' ? "" :
               <div className="modal-input">

                  <input type="text" disabled={this.state.loaderState === true || this.state.isOTPVerified === true} className="modal-fname" placeholder="Full Name" ref={(input) => { this.NameInput = input; }} name="FullName" value={this.state.formData.FullName} onChange={this.handleChangeValue} />
                  {/**<input type="text" className="modal-email" placeholder="Email" ref={(input) => { this.EmailInput = input; }} name="Email" value={this.state.formData.Email} onChange={this.handleChangeValue}/> */}

                  <div className='phone_number_input_container'>
                  <SelectCountryFlag currentSelected={this.state.countryCode} onSelected={this.flagSelected}/>
                  <div className='separator'></div>
                  <input style={{borderBottom: '1px solid transparent'}} type="text" disabled={this.state.loaderState === true || this.state.isOTPVerified === true} maxLength="10" className="modal-number" placeholder="Phone Number " ref={(input) => { this.NumberInput = input; }} name="Number" value={this.state.formData.Number} onChange={this.handleChangeValue}
                  />
                  </div>

                </div>}
              </React.Fragment> :
              <React.Fragment>
                {this.otpComponent()}
              </React.Fragment>
            }
            {this.state.renderOTPFormComponent === true ?
              '' :
            <div style={{maxWidth: '200px', margin: '0 auto'}}>
           {this.state.platFormType === 'linux' ? 
           <button className="done-button"
           disabled={this.state.loaderState}
           onClick={(e) => this.generateOTP(e)}>{
             this.state.loaderState === true ? <Loader /> : 'Join Waitlist'
           }</button>
          :
          <button className="done-button"
          disabled={this.state.loaderState}
          onClick={(e) => this.downloadMainController(e)}>{
            this.state.loaderState === true ? <Loader /> : 'Download'
          }</button>}
            </div>}

          </form>
        </div>

      </>
    )
  }

};

export default ModalForm;   