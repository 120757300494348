import React from 'react'
import './CompareFeatures.css';
import SvgCross from './SvgCross';

function CompareFeaturestwo() {
    return (
        <div className="compare_features_inner_container animate fadeIn five">
            <div>
            <div class="feature_set_browser mbw"><div aria-hidden="true">BROWSE THE WEB WITH PEACE OF MIND</div><div>Safety and Security.
            </div>
            <div style={{maxWidth: '662px', margin: '0px auto'}}>
			qikfox Safe Browser features <strong>advanced Safety and Security features</strong> that should have been a norm for web browsers. While other browsers allow access to all websites regardless of establishing trust and ownership, qikfox Safe browser takes a radical new approach. It guarantees that all websites allowed belong to legitimate businesses. Our intelligent systems <strong>work around the clock to keep you safe</strong> while you are surfing the web. 
            </div>
            </div>
            <div className="compare_features_table_main_container">
<table className="qikfox-u-data-table compare-table">
	<thead>
		<tr>
			<th>Safety and Security</th>
			<th scope="col"><img src="images/qikfox-favicon.svg" height="51" width="51" alt="Firefox" /></th>
			<th scope="col"><img src="images/CompareFeatures/chrome-icon.png" height="51" width="51" alt="Chrome" /></th>
			<th scope="col"><img src="images/CompareFeatures/safari-icon.png" height="51" width="51" alt="Edge" /></th>
            <th scope="col"><img src="images/CompareFeatures/firefox-icon.svg" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/brave-icon.png" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/edge-icon.png" height="51" width="51" alt="Opera" /></th>
			<th scope="col"><img src="images/CompareFeatures/ie-icon.png" height="51" width="51" alt="Brave" /></th>
			<th scope="col"><img src="images/CompareFeatures/vivaldi-icon.webp" height="51" width="51" alt="Internet Explorer" /></th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th scope="row">Basic Safe Browsing</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Advanced Safe Browsing</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Advanced Website Verification</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Fake Ads and Scam Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Anti-Phishing Technology</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Popup Protection</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Inbuilt Antimalware Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Real-Time Link Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
	</tbody>
</table>
</div>
            </div>

            <div>
            <div class="feature_set_browser mbw"><div aria-hidden="true">YOUR PRIVACY MATTERS</div><div>Privacy and Identity.
            </div>
            <div style={{maxWidth: '662px', margin: '0px auto'}}>
			<strong>Consumers are concerned about privacy</strong>, and rightly so. Breach of Privacy is a huge concern for consumers and businesses alike. In qikfox Safe Browser, numerous safety, security and <strong>privacy features come together to guard your privacy and protect your identity</strong> from online trackers and privacy violators. 
            </div>
            </div>
            <div className="compare_features_table_main_container">
<table className="qikfox-u-data-table compare-table">
	<thead>
		<tr>
			<th>Privacy and Identity</th>
			<th scope="col"><img src="images/qikfox-favicon.svg" height="51" width="51" alt="Firefox" /></th>
			<th scope="col"><img src="images/CompareFeatures/chrome-icon.png" height="51" width="51" alt="Chrome" /></th>
			<th scope="col"><img src="images/CompareFeatures/safari-icon.png" height="51" width="51" alt="Edge" /></th>
            <th scope="col"><img src="images/CompareFeatures/firefox-icon.svg" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/brave-icon.png" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/edge-icon.png" height="51" width="51" alt="Opera" /></th>
			<th scope="col"><img src="images/CompareFeatures/ie-icon.png" height="51" width="51" alt="Brave" /></th>
			<th scope="col"><img src="images/CompareFeatures/vivaldi-icon.webp" height="51" width="51" alt="Internet Explorer" /></th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th scope="row">Advanced Private mode</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Developer Mode Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Integrated Privacy Shield</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Private Web Search</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Advanced Tracking Protection</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Fingerprint Farbling</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Decentralized Identity Wallet</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Censor-Free Web3 Support</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
	</tbody>
</table>
</div>
            </div>

            <div>
            <div class="feature_set_browser mbw"><div aria-hidden="true">DESIGNED FOR YOUR DIGITAL LIFESTYLE</div><div>Productivity.
            </div>
            <div style={{maxWidth: '662px', margin: '0px auto'}}>
			qikfox Smart Browser is ready for web3 today. With web3 Handles, IPFS and Smart Stacks you can <strong>participate in the new decentralized Web with ease</strong>. Numerous productivity and design enhancements make qikfox Safe Browser <strong>super intuitive and easy to use.</strong> 
            </div>
            </div>
            <div className="compare_features_table_main_container">
<table className="qikfox-u-data-table compare-table">
	<thead>
		<tr>
			<th>Productivity</th>
			<th scope="col"><img src="images/qikfox-favicon.svg" height="51" width="51" alt="Firefox" /></th>
			<th scope="col"><img src="images/CompareFeatures/chrome-icon.png" height="51" width="51" alt="Chrome" /></th>
			<th scope="col"><img src="images/CompareFeatures/safari-icon.png" height="51" width="51" alt="Edge" /></th>
            <th scope="col"><img src="images/CompareFeatures/firefox-icon.svg" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/brave-icon.png" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/edge-icon.png" height="51" width="51" alt="Opera" /></th>
			<th scope="col"><img src="images/CompareFeatures/ie-icon.png" height="51" width="51" alt="Brave" /></th>
			<th scope="col"><img src="images/CompareFeatures/vivaldi-icon.webp" height="51" width="51" alt="Internet Explorer" /></th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th scope="row">Fast Browsing(Adaptive)</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Tabbed Browsing</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Bookmarks and Reading Lists</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Integrated Website Reviews</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Native web3 Support</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Peer to Peer Content Sharing</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Advanced Autofill</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="No" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Web3 Handles (Revolutionary)*</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="no" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
	</tbody>
</table>
</div>
            </div>


			<div>
            <div class="feature_set_browser mbw"><div aria-hidden="true">Decentralize Everything</div><div>Trustworthy Web3.
            </div>
            <div style={{maxWidth: '662px', margin: '0px auto'}}>
			<strong>Decentralized Internet is here to stay.</strong>qikfox Safe Browser offers advanced user-friendly Web3 features.<strong>Safety, Security and Privacy design principles</strong> help us to create a more equitable and trustworthy Web3.
            </div>
            </div>
            <div className="compare_features_table_main_container">
<table className="qikfox-u-data-table compare-table">
	<thead>
		<tr>
			<th>Decentralized Internet</th>
			<th scope="col"><img src="images/qikfox-favicon.svg" height="51" width="51" alt="Firefox" /></th>
			<th scope="col"><img src="images/CompareFeatures/chrome-icon.png" height="51" width="51" alt="Chrome" /></th>
			<th scope="col"><img src="images/CompareFeatures/safari-icon.png" height="51" width="51" alt="Edge" /></th>
            <th scope="col"><img src="images/CompareFeatures/firefox-icon.svg" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/brave-icon.png" height="51" width="51" alt="Safari" /></th>
			<th scope="col"><img src="images/CompareFeatures/edge-icon.png" height="51" width="51" alt="Opera" /></th>
			<th scope="col"><img src="images/CompareFeatures/ie-icon.png" height="51" width="51" alt="Brave" /></th>
			<th scope="col"><img src="images/CompareFeatures/vivaldi-icon.webp" height="51" width="51" alt="Internet Explorer" /></th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th scope="row">Decentralized Identity Wallet</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="no" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Universal Web3 Handles*</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Native IPFS Support</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
		<tr>
			<th scope="row">Identity Verification (KYC/KYB)*</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Safety and Security Scans</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Web3 Gateway Support</th>
            <td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Malware Protection*</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
        <tr>
			<th scope="row">Advanced Content Credentials*</th>
			<td><img alt="Yes" src="images/CompareFeatures/feature-check.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
			<td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
            <td><img alt="Yes" src="images/CompareFeatures/feature-dash.svg" height="24" width="24" /></td>
		</tr>
	</tbody>
	<div className='coming_soon_text'>*coming soon</div>
</table>
</div>
            </div>



        </div>
    )
}

export default CompareFeaturestwo