import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router';
import {useLocation } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import Cart from './Cart';
import './Navigation.css'


function SideNavBar(props) {
    const location = useLocation();
    useEffect(() => {
        setOpen(false);
        setopenBurgerbar(false);
        setTimeout(() => {
            setOpenDisplay(false);
          }, 400);
          setTimeout(() => {
            setopenBurgerbarDisplay(false);
          }, 400);
      }, [location]);
    const [open, setOpen] = useState(false);
    const [openBurgerbar, setopenBurgerbar] = useState(false);
    const [openDisplay, setOpenDisplay] = useState(false);
    const [openBurgerbarDisplay, setopenBurgerbarDisplay] = useState(false);
    const [contentShow, setcontentShow] = useState('');
    const [UserButton, setUserButton] = useState('');
    const [signupShow, setsignupShow] = useState(false);

    let changeClassname = "header-section-outer-1";
    let changeClassname2 = "header-section-outer";
    if(openBurgerbar){
        changeClassname2 = "header-section-outer opened"
    }
    if(open){
        changeClassname = "header-section-outer-1 opens";
    }else{
        changeClassname = "header-section-outer-1"
    }
    const  history = useHistory();
    const Innerpageburgerbar = (e) =>{
        e.preventDefault();
        let innerPageUrl = e.target.getAttribute('data-url');
        history.push(innerPageUrl);
    };
    function signUpButtonClick(){
        setsignupShow(true);
        setUserButton("signUpSection");
    }
    function LoginButtonClick(){
        setUserButton("LoginSection");
    }
    function handleClick(){
        setOpen(true);
        setcontentShow('User');
        setOpenDisplay(true);
    }
    function innerClick(){
        setOpen(false);
        setcontentShow('User');
        setTimeout(() => {
            setOpenDisplay(false);
          }, 400);
    }
    function handleClickBurger(){
        setopenBurgerbar(true);
        setcontentShow('Menu');
        setopenBurgerbarDisplay(true);
    }
    function innerClickBurger(){
        setopenBurgerbar(false);
        setcontentShow('Menu');
        setTimeout(() => {
            setopenBurgerbarDisplay(false);
          }, 400);
    }
    const RedirectSearchPage = () => {
        window.open('https://search.qikfox.com/');
    }
    const RedirectOffersPage = () => {
        window.open('https://offers.qikfox.com');
    }

    const RedirectExtensionsPage = () => {
        window.open('https://chrome.google.com/webstore/search/qikfox');
    }
    function Burgerbar(){
        return(
            <div>
            {/**<Cart /> */}
            <div id="navigation_01" className={changeClassname2}>
            <div className="abc">
            <div className="header-section">
            <div id="navbar_1" className={!openBurgerbar ? "navbar-logo" : "navbar-logo-close"}>
            <div id="user_1" className={!openBurgerbar ? "user" : "user-close"}><img src="/images/signup.svg" onClick={handleClick} alt="signup" /></div>
            <div className="burger-contain">
            <div className="burger-inside"></div>
            {!openBurgerbar ?  <div className="burger-logo" onClick={handleClickBurger}>
            <img src="/images/ic_menu.svg" alt ="menu-icon"/>
            </div> :  <div className="burger-logo-closed" onClick={innerClickBurger}>
            <img src="/images/ic-menu-white.svg" alt ="menu-icon"/>
            </div>}
            </div>
            </div>
            </div>
            <div id="out_contain_01" className={!openBurgerbarDisplay ? "out-contain" : " out-contain out-contain-open"}>
            <div className="outer-container">
            <div className="right-bar-outer">
            <div className="right-burger">
            <img src="/images/close-icon.svg" alt="cross" onClick={innerClickBurger} />
            </div>
            </div>
            <div className="right-burger-list">
            <ul>
            <li onClick={Innerpageburgerbar} data-url="/">The Browser</li>
            <li onClick={RedirectSearchPage}>Search Engine</li>
            <li onClick={Innerpageburgerbar} data-url="/digital-identity">Digital Identity</li>
            <li onClick={RedirectExtensionsPage}>Extensions</li>
            <li onClick={Innerpageburgerbar} data-url="/trustworthy-internet">Trustworthy Internet</li>
            <li onClick={Innerpageburgerbar} data-url="/about-us">About Us</li>
            <li className='buy_button_small_menu' onClick={RedirectOffersPage}>Buy</li>
           {/** <li onClick={Innerpageburgerbar} data-url="/qik-handle">Buy a Handle or a Domain</li> */}
           {/**            <li><HashLink to ="/support#WhitePapers">Whitepapers</HashLink></li>
            <li onClick={RedirectBlogPage}>Blog</li>
            <li onClick={Innerpageburgerbar} data-url="/about-us">About Us</li>
            <li onClick={Innerpageburgerbar} data-url="/support">Help Center</li> */}
            </ul>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        )
    }
    function LoginSection(){
        return(
            <div className="right-user-list">
            <div className="right-user-form">
            <h2>Login</h2>
            <p>Log into your qikfox account</p>
            <form>
            <div className="form-inner-content">
            <input type="number" placeholder="Phone Number" />
            <div className="form-center-line"></div>
            <input type="password" placeholder="Create Password" />
            </div>
            <button type="submit">Login</button>
            <a href="#">Forgot my password</a>
            </form>
            <div className="right-user-inner">
            <p>Don't have an account? Sign up now, it only takes a few minutes.</p>
            <button onClick={signUpButtonClick}>Sign Up</button>
            <a href="#">Resend one-time activation code ...</a>
            </div>
            </div>
            </div>
        )
    }
    function SignUpSection(){
        return(
            <div className="right-user-list">
                <div className= "right-user-form">
                <h2>Sign Up</h2>
                <p>Register for your qikfox account</p>
                <form>
                <div className="form-inner-content">
                <input type="number" placeholder="Phone Number" />
                <div className="form-center-line"></div>
                <input type="password" placeholder="Create Password" />
                </div>
                <button type="submit">Sign Up</button>
                <a href="#">Resend one-time activation code ...</a>
                </form>
                <div className="right-user-inner">
                <p>Already have an account? Login now.</p>
                <button onClick={LoginButtonClick}>Login</button>
                </div>
                </div>
                </div>
        )
    }
    function closedBar(){
            return(
             <div>
            {/** <Cart /> */}
             <div className={changeClassname}>
             <div className="abc-1">
             <div className="header-section-1">
             <div className={!open ? "navbar-logo-1" : "navbar-logo-close-1"}>
             {!open ?  <div className="user" onClick={handleClick}>
             <img src="/images/signup.svg" alt ="menu-icon"/>
             </div> :  <div className="user-opened" onClick={innerClick}>
             <img src="/images/account-icon-white.svg" alt ="menu-icon"/>
             </div>}
             <div className="burger-contain-1">
             <div className="burger-inside-1"></div>
             <div className={!open ? "burger-logo-1" : "burger-logo-hidden"} onClick={handleClickBurger}>
             <img src="/images/ic_menu.svg" alt ="menu-icon" onClick={handleClickBurger}/>
             </div>
             </div>
             </div>
             </div>
             <div className={!openDisplay ? "out-contain-1" : " out-contain-1 out-contain-open"}>
             <div className="outer-container-1">
             <div className="right-bar-outer-1">
             <div className="right-burger-1">
             <img src="/images/close-icon.svg" alt="cross" onClick={innerClick} />
             </div>
             </div>
             {UserButton === "LoginSection" ? LoginSection() : SignUpSection()}
             </div>
             </div>
             </div>
             </div>
             </div>
            )
}

    return (
        <div className="showsideNav">
        {contentShow === "User" ? 
        closedBar() :
        Burgerbar() }
        </div>
    )
}

export default SideNavBar
