import React from 'react'
import '../PrivacyPolicy/Privacy.css'
function TermUse() {
    return (
        <div className={window.location.pathname === "/terms-of-use" ? "table_upper_main animate fadeIn five" : "table_upper_main"}>
        <div class="py-12 px-6 lg:px-0 container flex flex-wrap">
	<aside class="toc mb-4 w-full lg:w-15/50 lg:pr-5 lg:toc--sticky"> <strong className="table_content_heading">Table of contents</strong>
		<nav id="TableOfContents">
			<ul>
				<li><a href="#acceptance-of-terms">Acceptance of Terms</a></li>
				<li><a href="#modification-of-terms-of-use">Modification of Terms of Use</a></li>
				<li><a href="#other-policies">Other Policies</a></li>
				<li><a href="#qikfox-browser-license">Qikfox Browser License</a></li>
				<li><a href="#rules-and-conduct">Rules and Conduct</a></li>
				<li><a href="#trademarks--patents">Trademarks &amp; Patents</a></li>
				<li><a href="#termination">Termination</a></li>
				<li><a href="#disclaimer">Disclaimer</a></li>
				<li><a href="#idemnification">Idemnification</a></li>
				<li><a href="#limitation-of-liability">Limitation of Liability</a></li>
				<li><a href="#miscellaneous">Miscellaneous</a></li>
				<li><a href="#copyright-dispute-policy">Copyright Dispute Policy</a></li>
				<li><a href="#qikfox-Antivirus-Subscription">Qikfox Antivirus Subscription</a></li>
                <li><a href="#qikfox-smart-stacks">Qikfox Smart Stacks (Backup Drive) Subscription</a></li>
				<li><a href="#qikfox-toatal-protection">Qikfox Total Protection Subscription</a></li>
			</ul>
		</nav>
	</aside>
	<div class="w-full lg:w-35/50 lg:pl-5">
		<p><strong>PLEASE READ THESE TERMS OF USE (“AGREEMENT” OR “TERMS OF USE”) CAREFULLY BEFORE USING THE SERVICES OFFERED BY QIKFOX CYBERSECURITY SYSTEMS, INC. (“QIKFOX”). THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE QIKFOX SMART BROWSER AND ALL RELATED SERVICES, INCLUDING, WITHOUT LIMITATION, ANY FEATURES, CONTENT, WEBSITES OR APPLICATIONS OFFERED FROM TIME TO TIME BY QIKFOX IN CONNECTION THEREWITH (COLLECTIVELY “SERVICE(S)”). BY USING THE SERVICES IN ANY MANNER, YOU AGREE TO BE BOUND BY THIS AGREEMENT.</strong></p>
		<h2 id="acceptance-of-terms">Acceptance of Terms <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#acceptance-of-terms" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>The Service is offered subject to acceptance without modification of all of these Terms of Use and all other operating rules, policies and procedures that may be published from time to time in connection with the Services by Qikfox. In addition, some services offered through the Service may be subject to additional terms and conditions promoted by qikfox from time to time; your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms of Use by this reference.
        </p>
		<p>Qikfox may, in its sole discretion, refuse to offer the Service to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Service is revoked in such jurisdictions.</p>
		<h2 id="modification-of-terms-of-use">Modification of Terms of Use <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#modification-of-terms-of-use" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>qikfox reserves the right, at its sole discretion, to modify or replace any of the Terms of Use, or change, suspend, or discontinue the Service (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the qikfox websites or Service or by sending you an email. qikfox may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability. It is your responsibility to check the Terms of Use periodically for changes. Your continued use of the Service following the posting of any changes to the Terms of Use constitutes acceptance of those changes. Paid features or offerings are subject to their own terms and conditions, if any. </p>
		<h2 id="other-policies">Other Policies <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#other-policies" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>For information regarding qikfox’s treatment of personally identifiable information, please review qikfox’s current Privacy Policies at <a target="_blank" rel="noopener noreferrer" href="https://qikfox.com/privacy">https://qikfox.com/privacy</a>. If you believe that material or content residing on or accessible through the Service infringes a copyright, please review qikfox’s Copyright Dispute Policy at the bottom of these Terms of Service.
        </p>
		<h2 id="qikfox-browser-license">Qikfox Smart Browser License <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-browser-license" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>The executable code version of the Qikfox smart browser is made available under the terms set forth below. Source code for parts of the qikfox browser may be available for use under open source licenses and accessible via <a target="_blank" rel="noopener noreferrer" href="https://github.com/qikfox">https://github.com/qikfox</a>. Nothing in this Agreement will be construed to limit any rights granted under such open source licenses with respect to code specifically covered by such licenses.</p>
		<p>Subject to the terms hereof, qikfox grants you a personal, non-exclusive license to install and use the executable code version of the qikfox browser. qikfox and its licensors shall retain all intellectual property rights in the qikfox browser (and Service), except for the rights expressly granted in this Agreement. You may not remove or alter any trademark, or logo (collectively, “Marks”), copyright or other proprietary notice on the qikfox browser. This license does not grant you any right to use Marks of qikfox or its licensors. If you breach this Agreement, the above license and your right to use the qikfox browser will terminate immediately and without notice. Upon termination, you must destroy all copies of the qikfox browser.</p>
		<h2 id="rules-and-conduct">Rules and Conduct <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#rules-and-conduct" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>As a condition of use, you promise not to use the Service for any purpose that is prohibited by the Terms of Use. For purposes of the Terms of Use, the term “Content” includes, without limitation, any information, data, text, photographs, videos, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or through the Service. By way of example, and not as a limitation, you shall not (and shall not permit any third party to) take any action (including contributing any Content) that: would constitute a violation of any applicable law, rule or regulation; infringes any intellectual property or other right of any other person or entity; is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane; constitutes unauthorized or unsolicited advertising, junk or bulk e-mail; contains software viruses or any other similar computer codes, files, or programs; or impersonates any person or entity.
        Further, you shall not (directly or indirectly): (i) take any action that imposes or may impose an unreasonable or disproportionately large load on Qikfox’s (or its third party providers’) infrastructure; or (ii) interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service; (iii) bypass any measures Qikfox may use to prevent or restrict access to the Service (or parts thereof).
        </p>
		<h2 id="trademarks--patents">Trademarks &amp; Patents <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#trademarks--patents" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>All Qikfox logos, marks and designations are trademarks or registered trademarks of Qikfox. All other trademarks mentioned in this website are the property of their respective owners. The trademarks and logos displayed on this website may not be used without the prior written consent of Qikfox or their respective owners. Portions, features and/or functionality of Qikfox’s products may be protected under Qikfox patent applications or patents.</p>
		<h2 id="termination">Termination <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#termination" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>Qikfox may terminate your access to all or any part of the Service at any time if you fail to comply with these Terms of Use, which may result in the forfeiture and destruction of all information associated with your account. Further, either party may terminate the Services for any reason and at any time upon written notice. If you wish to terminate your account, you may do so by following the instructions on the Service. Any fees paid hereunder are non-refundable. Upon any termination, all rights and licenses granted to you in this Agreement shall immediately terminate, but all provisions hereof which by their nature should survive termination shall survive termination, including, without limitation, warranty disclaimers, indemnity and limitations of liability.</p>
		<h2 id="disclaimer">Disclaimer <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#disclaimer" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>ALL USE OF THE SERVICE AND ANY CONTENT IS UNDERTAKEN ENTIRELY AT YOUR OWN RISK. THE SERVICE (INCLUDING, WITHOUT LIMITATION, THE QIKFOX BROWSER AND ANY CONTENT) IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
		<h2 id="idemnification">Idemnification <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#idemnification" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>You shall defend, indemnify, and hold harmless Qikfox, its affiliates and each of its, and its affiliates employees, contractors, directors, suppliers and representatives from all liabilities, losses, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to (i) your use or misuse of, or access to, the Service, or (ii) your violation of the Terms of Use or any applicable law, contract, policy, regulation or other obligation. Qikfox reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Qikfox in connection therewith.</p>
		<h2 id="limitation-of-liability">Limitation of Liability <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#limitation-of-liability" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>IN NO EVENT SHALL QIKFOX OR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, OR SUBSTITUTE GOODS OR SERVICES, (II) FOR YOUR RELIANCE ON THE SERVICE OR (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE FEES PAID BY YOU FOR THE SERVICE OR, IF GREATER, $450. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
		<h2 id="miscellaneous">Miscellaneous <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#miscellaneous" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
        <p>The Terms of Use are the entire agreement between you and Qikfox with respect to the Service, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and Qikfox with respect to the Service. If any provision of the Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Use will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. Qikfox shall not be liable for any failure to perform its obligations hereunder due to any cause beyond Qikfox’s reasonable control. The Terms of Use are personal to you, and are not assignable or transferable by you except with Qikfox’s prior written consent. Qikfox may assign, transfer or delegate any of its rights and obligations hereunder without consent. No agency, partnership, joint venture, or employment relationship is created as a result of the Terms of Use and neither party has any authority of any kind to bind the other in any respect. Except as otherwise provided herein, all notices under the Terms of Use will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or two days after it is sent, if sent for next day delivery by recognized overnight delivery service</p>
		<h2 id="copyright-dispute-policy">Copyright Dispute Policy <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#copyright-dispute-policy" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
        <p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that any Content made available in connection with the Service infringes your copyright, you (or your agent) may send us a notice requesting that the Content be removed, or access to it blocked as set forth below (see https://www.copyright.gov/ for further details). It is Qikfox’s policy to (1) block access to or remove material that it believes in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users; and (2) remove and discontinue service to repeat offenders. The address of Qikfox’s Designated Agent to Receive Notification of Claimed Infringement (“Designated Agent”) is listed at the end of this policy.
        </p>		
<p><strong>A. Procedure for Reporting Copyright Infringements:</strong>  If you believe that material or content residing on or accessible through the Qikfox Service infringes a copyright, please send a notice of copyright infringement containing the following information to the Designated Agent listed below: 1. A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed; 2. Identification of works or materials being infringed; 3. Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Qikfox is capable of finding and verifying its existence; 4. Contact information about the notifier including address, telephone number and, if available, e-mail address; 5. A statement that the notifier has a good faith belief that the material identified in (3) is not authorized by the copyright owner, its agent, or the law; and 6. A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</p>
		<p><strong>B. Once Proper Bona Fide Infringement Notification is Received by the Designated Agent:</strong> It is Qikfox’s policy: 1. to remove or disable access to the infringing material; 2. to notify the content provider, member or user that it has removed or disabled access to the material; and 3. that repeat offenders will have the infringing material removed from the system and that Qikfox will terminate such content provider’s, member’s or user’s access to the service</p>
		<p><strong>C. Procedure to Supply a Counter-Notice to the Designated Agent:</strong>f the content provider, member or user believes that the material that was removed or to which access was disabled is either not infringing, or the content provider, member or user believes that it has the right to post and use such material from the copyright owner, the copyright owner’s agent, or pursuant to the law, the content provider, member or user must send a counter-notice containing the following information to the Designated Agent listed below: 1. A physical or electronic signature of the content provider, member or user; 2. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled; 3. A statement that the content provider, member or user has a good faith belief that the material was removed or disabled as a result of mistake or a misidentification of the material; and 4. Content provider’s, member’s or user’s name, address, telephone number, and, if available, e-mail address and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider’s, member’s or user’s address is located, or if the content provider’s, member’s or user’s address is located outside the United States, for any judicial district in which Qikfox is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.
        </p>
		<p>If a counter-notice is received by the Designated Agent, Qikfox may send a copy of the counter-notice to the original complaining party informing that person that Qikfox may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Qikfox’s discretion.</p>
		<p><strong>Notices and counter notices with respect to the Service should be sent to Qikfox at:</strong>
        <p>Copyright Designated Agent:</p>
        <p>Qikfox Cybersecurity Systems, Inc.
        </p>
        <p>55 E 3rd Ave,</p>
        <p>San Mateo, CA 94401</p>
        <p>Support: legal@qikfox.com</p></p>
		<h2 id="qikfox-Antivirus-Subscription">Qikfox Antivirus Subscription<a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-Antivirus-Subscription" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>Qikfox Antivirus is a paid service and at your sole discretion, you can pay for the subscription via a Monthly or Yearly plan directly through Qikfox Store or through an authorized partner, reseller or a distributor. Terms of Sale are governed by their respective sale channels. Partners may or may not have additional Terms and Conditions. </p>
        <h2 id="qikfox-smart-stacks">Qikfox Smart Stacks (Backup Drive) Subscription <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-smart-stacks" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>Qikfox Smart Stacks is a paid service and at your sole discretion, you can pay for the subscription via a Monthly or Yearly plan directly through Qikfox Store or through an authorized partner, reseller or a distributor. Terms of Sale are governed by their respective sale channels. Partners may or may not have additional Terms and Conditions. </p>
		<h2 id="qikfox-toatal-protection">Qikfox Total Protection Subscription <a class="text-lg align-middle text-neutral-200 hover:text-neutral-700 transition-colors ease-in-out duration-100" href="#qikfox-toatal-protection" title="Permalink to this headline"><i class="fas fa-link"></i></a></h2>
		<p>Qikfox Total Protection is a bundle (that comprises Qikfox Browser + Antivirus + Smart Stacks + Managed Services Subscription) is a paid service and at your sole discretion, you can pay for the subscription via a Monthly or Yearly plan directly through Qikfox Store or through an authorized partner, reseller or a distributor. Terms of Sale are governed by their respective sale channels. Partners may or may not have additional Terms and Conditions. </p>
		<p><strong className="terms_content">Terms of Use Effective Date:</strong>
        <br></br>
			 June 21, 2021</p>
	</div>
</div>
        </div>
    )
}

export default TermUse
