import * as CONSTANTS from '../constants'


const initialState = {
    page: 'home'
}

export default (state = initialState, {type, payload}) =>{
    switch(type){
        case CONSTANTS.SET_PAGE:
          return{
            ...state,
            pageType: payload
          }
        default:
            return state
    }
}