import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { pageContent } from '../StaticPageContent';
import './TestCarousel.css'
import Arrows from '../Svgs/Arrows';



class DemoCarousel extends Component {

  constructor() {
    super();
    this.state = {
      currentSlide: 0,
      autoPlay: true,
      isFadeOut: false,
      currentText: 'Browser',
      renderDataJSON: pageContent
    };
  }

  startAutoPlay = () =>{
    this.setIntervalCaraousel = setInterval(() => {
      this.nextAutoPlay();
    }, 7000);
  }

  clearSetInterval = () =>{
    clearInterval(this.setIntervalCaraousel);
  }

  restartAutoplay=()=>{
    this.restartPlay && clearTimeout(this.restartPlay)
    this.restartPlay=setTimeout(()=>{
      this.startAutoPlay();
    },10000)
  }

  componentDidMount(){
    let renderData = this.props.page !== undefined && this.props.page === 'identity' ? this.props.staticData : pageContent
    this.setState({renderDataJSON: renderData})

    this.startAutoPlay();
    return(() =>{
      this.clearSetInterval();
    })
  }

  //    componentDidMount(){
  //     if(this.state.autoPlay === true){
  //         setInterval(() =>{
  //             this.next();
  //         },4000);
  //       }else{}
  //        console.log('update phase called');
  //    }

  mouseClickNext = () => {
    this.setState({ autoPlay: false });
    this.clearSetInterval();
    this.restartAutoplay()
    this.next();
  }

  mouseClickPrev = () => {
    this.setState({ autoPlay: false });
    this.clearSetInterval();
    this.restartAutoplay()
    this.prev();
  }

  nextAutoPlay  = () => {
    let maxSlide = this.props.page && this.props.page === 'identity' ? 2 : 4
    if (this.state.currentSlide >= 0 || this.state.currentSlide <= maxSlide) {
      let value = this.state.currentSlide;
      value = value + 1;
      // console.log('0 and 4 condition value', value)
      this.setState((state) => ({
        currentSlide: state.currentSlide + 1,
      }));
      this.props.propsSlideValue(value);
    }
    if (this.state.currentSlide > maxSlide) {

      // console.log('4 condition value', this.state.currentSlide)
      this.setState({ currentSlide: 0 });
      this.props.propsSlideValue(0);
    }
  };

  next = () => {
    let maxSlide = this.props.page && this.props.page === 'identity' ? 2 : 4
    if (this.state.currentSlide >= 0 || this.state.currentSlide <= maxSlide) {
      let value = this.state.currentSlide;
      value = value + 1;
      console.log('0 and 4 condition value', value)
      this.setState((state) => ({
        currentSlide: state.currentSlide + 1,
      }));
      this.props.propsSlideValue(value);
    }
    if (this.state.currentSlide >= maxSlide) {

      console.log('4 condition value', this.state.currentSlide)
      this.setState({ currentSlide: 0 });
      this.props.propsSlideValue(0);
    }
  };

  prev = () => {
    let maxSlide = this.props.page && this.props.page === 'identity' ? 2 : 7
    if (this.state.currentSlide >= 0 || this.state.currentSlide <= maxSlide) {
      let value = this.state.currentSlide;
      value = value - 1;
      this.setState((state) => ({
        currentSlide: state.currentSlide - 1,
      }));
      this.props.propsSlideValue(value);
    }
    if (this.state.currentSlide < 1) {
      this.setState({ currentSlide: this.state.currentSlide });
      this.props.propsSlideValue(this.state.currentSlide);
    }
  };



updateCurrentSlide = (index) => {
 // console.log("index", index);
  // const { currentSlide } = this.state;

  // if (currentSlide !== index) {
  //     this.setState({
  //         currentSlide: index,
  //     });
  // }
};
toggleFadeOut = () => {
  if (!this.state.isFadeOut) {
    this.setState({ isFadeOut: true });

    setTimeout(() => {
      this.setState((prevState) => ({
        currentText: prevState.currentText === 'Browser' ? 'metaOS' : 'Browser',
      }));

      setTimeout(() => {
        this.setState({ isFadeOut: false });
      }, 100); // Delay before fading back in
    }, 400); // Delay before switching to the alternate text
  }
};

render() {
  const { isFadeOut, currentText } = this.state;
  return (
    <div className='browser-inner-body-main-container'>
      <div className='browser-inner-body-main-inner'>
      <div className='browser_upper_container'
      style={{
        ...(this.props.page && this.props.page === 'identity') ?
       { cursor: 'inherit'}
        : {}
      }}
      onClick={
        () =>{
          if(this.props.page && this.props.page === 'identity'){

          }else{
            this.toggleFadeOut()
          }
        }
      }>
      {
        this.props.page && this.props.page === 'identity' ?
        <div>
          <img src="/images/identity/qikPass.png" className='qikpass-logo'/>
        </div>
        :
        <React.Fragment>
          <div className='browser_image_container'><img src="/images/qikfox-icon.png" width="40" height="auto"/></div>

          <div
              className={`browser_text_container`}
            >
              <div className='browser_text_main_heading_qikfox'>qikfox</div> 
              <span className={`browser_text_inner_fade ${isFadeOut ? 'fade-out' : 'fade-in'}`}> {currentText}</span>
            </div>
        </React.Fragment>
      }
      </div>
        {/**  <button className='abc-btn' style={{marginLeft: '100px'}} onClick={this.next}>Next</button> */}
        <Carousel
          animationHandler='fade'
        //  autoPlay={this.state.autoPlay}
          //infiniteLoop={true}
          transitionTime={1500}
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          swipeable={false}
          showThumbs={false}
        >
          {this.state.renderDataJSON.map((page, index) => (
            <div className="browser-page">
              <div data-key={index} id={`browser-page-${index}`}>
                <a href={`#browser-page-${index + 1}`}></a>
                <div className=
                {`browser_inner_body_main_heading ${this.props.page && this.props.page === 'identity' ? 'browser_inner_body_main_heading_identity' : ''}`}
                >{page.heading}</div>
                <div key={index} className=
                {`browser_inner_body_main_text ${this.props.page && this.props.page === 'identity' ? 'browser_inner_body_main_text_identity' : ''}`}>{page.subText}</div>
              </div>
            </div>
          ))}
        </Carousel>
        <div className='caraousel_button_container_outer'>
          <div className='caraousel_button_inner'>
            <div className='btn-container-prev' onClick={this.mouseClickPrev}>
              <Arrows className='abc-btn' />
            </div>
            <div className='btn-container-next' onClick={this.mouseClickNext}>
              <Arrows />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

export default DemoCarousel;