import React, {useState, useEffect} from 'react'
import LoadingOverlay from 'react-loading-overlay';
import { useHistory, useLocation } from 'react-router-dom';
import './form.css';


const SERVER_FETCH_OPTIONS = {
    mode: 'cors',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}


function Form() {
    const history = useHistory();
    const location = useLocation();
    const [URL, setURL] = useState('');
    const [businessName, setbusinessName] = useState('');
    const [email, setemail] = useState('');
    const [requestText, setrequestText] = useState('');
    const [URLError, setURLError] = useState('');
    const [businessNameError, setbusinessNameError] = useState('');
    const [emailError, setemailError] = useState('');
    const [requestTextError, setrequestTextError] = useState('');
    const [chekedValueError1, setchekedValueError1] = useState('');
    const [textareaCharactersLength, settextareaCharactersLength] = useState();
    const [modalLoaderState, setmodalLoaderState] = useState(false);
    const [feedbackState, setfeedbackState] = useState(false);
    const [checkCOunt, setcheckCOunt] = useState(0);
    const [ticketValue, setticketValue] = useState('');
    const [formSubmitError, setformSubmitError] = useState(false);
    const [checkdValues, setcheckdValues] = useState(
        [{name: "As myself", value: false}, {name: "As the business in question", value: false},
        {name: "On behalf of someone else", value: false}, {name: "Anonymously", value: false}, {name: "On behalf of a business", value: false}]
    );
    const [hover, setHover] = useState(false);
    const onHover = () => {
      setHover(true);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    useEffect(() => {
       var GetURLValue =  getParameterByName('url',window.location.href);
       setURL(GetURLValue);
    },[location]);

    function getParameterByName(name, get) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(get);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

    const onHandleChecked = (event, index) => {
        let data = [...checkdValues];

        const newArr = data.map(obj => {
            return {...obj, value: false};
        });

        newArr[index].value = !newArr[index].value;

        setcheckdValues(newArr);
        let bool = [true]
        let result = newArr.filter(val=>bool.includes(val.value));
        setcheckCOunt(result.length);
    }

    const validate = () => {
        let URLError = ""
        let businessNameError = ""
        let emailError = ""
        let requestTextError = ""
        let checkedValueError = ""
        let checkedStatusarray = [...checkdValues]
        let bool = [true]
        let result = checkedStatusarray.filter(val=>bool.includes(val.value));
        if(result.length === 0){
            checkedValueError = "Please select at least one option."
        }else{}
        let regexp = /^[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/g
        let testUrlValidation = regexp.test(URL);
        if(testUrlValidation !== true || URL === ""){
            URLError = "Please enter a website."
        }else{
        }
        if(!email.includes('@') || !email.includes('.com') || email === ""){
          emailError = "Please enter an email."
        }else{
        }
        if(requestText === "" || textareaCharactersLength > 1500){
            requestTextError = "Please describe the request.";
        }else{
        }
        if(businessName === ""){
            businessNameError = "Please enter name of the business.";
        }else{
        }
        if(URLError || businessNameError || emailError || requestTextError || checkedValueError){
            setURLError(URLError);
            setbusinessNameError(businessNameError);
            setrequestTextError(requestTextError);
            setemailError(emailError);
            setchekedValueError1(checkedValueError);
            return false;
        }else{
            return true;
        }
    }

    const HandleSubmit = async () => {
        const isValid =  validate();
        if(isValid){
            let checkedStatusarray = [...checkdValues]
            let bool = [true]
            let result = checkedStatusarray.filter(val=>bool.includes(val.value));
            const data = {
                "url": URL,
                "businessName": businessName,
                "emailId": email,
                "requestType": result[0].name,
                "requestDescription": requestText
            }
            setmodalLoaderState(true);
           try{
            const response = await fetch(process.env.REACT_APP_REQUEST_A_REVIEW_URL,
                {...SERVER_FETCH_OPTIONS,  body: JSON.stringify(data)}
            )

            const result = await response.json()

            console.log('response', result)

            if(result.status === 1){
                setmodalLoaderState(false);
                setfeedbackState(true);
                setticketValue(result.ticket);
                return
            }
            throw new Error('Failed to request the server with an unknown Error')

           }catch(e){
            handleServerErrState()
           }
        }
    }

    function handleServerErrState() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setmodalLoaderState(false);
        setformSubmitError(true);
    }

    const handleErrorFOrm = () => {
        if(formSubmitError === true && feedbackState === false){
            var a  = document.querySelector('.main-content');
            a.style.display = 'none';
            return(
                <div style={{textAlign: 'center'}} className="warningmsgRequest_1">Error in Submitting the form.<br></br>Please Try again Later.</div>
            )
        }
    }
    //   function CopyTicket(containerid){
    //     var range = document.createRange();
    //   range.selectNode(containerid); //changed here
    //   window.getSelection().removeAllRanges(); 
    //   window.getSelection().addRange(range); 
    //   document.execCommand("copy");
    //   window.getSelection().removeAllRanges();
      
    //   var tooltip = document.getElementById("TicketTooltip");
    //   var Textupdate=document.getElementById("ticket_number");
    //   tooltip.innerHTML = "Ticket Copied: " + Textupdate.textContent;
    // }

    return (
        <>
        {modalLoaderState === true ?
            <div id="ModalReport_1" className="transition_modal">
            <div className="modal-content-outer">
                <div className="modal-content_1 modal-content-report_1">
                    <div className="modal-body report-popup_1" id="modal-body">
                        <div className="Businessloader_1"></div>
                    </div>
                </div>
            </div>
        </div> : ''}
        <div>
        {handleErrorFOrm()}
        <div className={window.location.pathname === "/request-review" ? "main-content animate fadeIn five" : "main-content"}>
        {feedbackState === true ?
            <div className={feedbackState === true ? "inner-content animate fadeIn five": ""}>
            <div className="Report-locked">
                <div className="report-title">Thank you!</div>
                <div className="report-details-text">We have received your request. qikfox Site Review team will review the website and inform you of the decision. If you are the site owner, you can appeal the decision at <a href="mailto:support@qikfox.com">site-review@qikfox.com</a>.</div>
                <div className="report-verification" style={{textAlign: 'center'}}><img src="/images/Report-Ticket-Verification.svg" />
                </div>
               
            </div>
        </div>
    :
    <div className="content-container content-container-report">
    <div id="report-msg">
        <div className="inner-content">
            <div className="report-title">Request a Review</div>
            <div className="report-details-text">If you believe have been scammed, please file a report or give us a call at <a style={{textDecoration: "none", color: '#6e7272', fontFamily: 'Gilroy-Medium'}} href="" className="calltoaction">+1-888-801-1555</a> for quick and easy assistance.</div>
            <div className="report-form-main">
                <form>
                    <div className="report-input-section">
                        <div className="report-input-label-main">
                            <div className="report-input-label"> Website Link (URL) </div>
                        </div>
                        <div className="report-input-field">
                        {URLError !== "" ? <div className="warningmsgRequest">{URLError}</div> : ""}
                            <input type="text" className={URLError !=="" ? "report-textbox err-msg-border" : "report-textbox"} id="website" value={URL} onChange={e => setURL(e.target.value)} placeholder="Enter the Website URL" /> </div>
                    </div>
                    <div className="report-input-section">
                        <div className="report-input-label-main">
                            <div className="report-input-label"> Business Name </div>
                        </div>
                        <div className="report-input-field">
                        {businessNameError !== "" ? <div className="warningmsgRequest">{businessNameError}</div> : ""}
                            <input type="text"  className={businessNameError !=="" ? "report-textbox err-msg-border" : "report-textbox"} id="business-name" value={businessName} onChange={e => setbusinessName(e.target.value)} placeholder="Name of the Business" /> </div>
                    </div>
                    <div className="report-input-section">
                        <div className="report-input-label-main">
                            <div className="report-input-label"> Your Email ID </div>
                        </div>
                        <div className="report-input-field">
                        {emailError !== "" ? <div className="warningmsgRequest">{emailError}</div> : ""}
                            <input type="text" className={emailError !=="" ? "report-textbox err-msg-border" : "report-textbox"} id="email" value={email} onChange={e => setemail(e.target.value)} placeholder="Enter your email address" /> </div>
                    </div>
                    <div className="report-input-section">
                        <div className="report-input-label-main">
                            <div className="report-input-label"> I’m requesting </div>
                        </div>
                        <div className="report-input-field">
                        {chekedValueError1 !== "" ? <div className="warningmsgRequest">{chekedValueError1}</div> : ""}
                            <div className="report-reason-main">
                                {checkdValues.map((item, index) => (
                                    <div className="report-reason">
                                    <label style={{marginBottom: '0px'}} className="containercheck">
                                        <input key={index} type="checkbox" checked={item.value} onChange={(e) => onHandleChecked(e, index)} value="As myself" name="requestingSelect[]" className="reportcheck" data-role="none" /> <span className="checkmark"></span></label>
                                    <div className="report-reason-text">{item.name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="report-input-section">
                        <div className="report-input-label-main">
                            <div className="report-input-label"> Describe the Request </div>
                        </div>
                        <div className="report-input-field">
                        {requestTextError !== "" ? <div className="warningmsgRequest">{requestTextError}</div> : ""}
                            <textarea id="request-desc" maxlength="1500" className={requestTextError !=="" ? "report-textarea err-msg-border" : "report-textarea"} placeholder="Please give as many details as you can about the business. It helps the team to verify the legitimacy of the website and the entity associated with it." rows="4" cols="50"
                            value={requestText} onChange={e => {
                                setrequestText(e.target.value);
                                settextareaCharactersLength(1500 - (e.target.value.length));
                            }
                            }></textarea>
                            <div className="report-description-count" id="charNum">{textareaCharactersLength !== undefined  ? 
                            textareaCharactersLength : 1500} characters remaining</div>
                        </div>
                    </div>
                    <div className="report-submit">
                        <button type="button" className="Reportbutton" onClick={HandleSubmit} >Request</button></div>
                </form>
                <div className='get_help_text_container'>
                <div
                onClick={() => history.push('/support')}
                >Get Help</div>
                </div>
            </div>
        </div>
    </div>
</div>
}
</div>
        </div>
        </>
    )
}

export default Form