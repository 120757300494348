import React from 'react'
import Navigation from './Navigation'
import TopHeader from './TopHeader/TopHeader'

function MainNavigationContainer() {
    return (
        <>
        <TopHeader />
          <Navigation />  
        </>
    )
}

export default MainNavigationContainer
