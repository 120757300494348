import React, { Component } from 'react'
import './NoPageFound.css';

class NoPagefound extends Component {
  
    render(){
        return (
            <div className="main-wrapper-2">
            <div className="page">
            <h1 className="page-not-found">Page not found!</h1>
            </div>
            </div>
        );
    }
    
};

export default NoPagefound;
